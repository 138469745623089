.web {

  /* Login Page design */

  app-login {
    ion-content {
      &::part(background) {
        background: #EEF0F2 !important;
      }
    }
  }

  .web_login {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 730px;
    background: var(--ion-white-color);

    .side_Sec {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 90%;
      padding: 20px 0;
    }

    .left {
      background: var(--ion-bg-theme);
      height: 100%;
      background-image: url(../assets/svg/login_bg.svg);
      background-size: cover;
      position: relative;
      background-position: initial;
      background-repeat: no-repeat;
      padding: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;

      .logo_sec {
        ion-img {
          width: max-content;
          height: 56px;
          margin-left: 0;
          text-align: left;
        }
      }

      .logo_sub_text {
        p {
          font-size: 14px;
          margin: 0;
          color: var(--ion-paid-color);
          font-weight: 600;
        }
      }

      .logo_head_text {
        h1 {
          font-size: 36px;
          font-weight: 700;
          color: var(--ion-white-color);
          line-height: 46px;
          width: 70%;
        }

        p {
          font-size: 16px;
          width: 70%;
          color: var(--ion-white-color);
          line-height: 30px;
        }
      }

      .list_texts {
        ul {
          &.circle-checkmark {
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              display: flex;
              align-items: center;

              ion-icon {
                padding-right: 10px;
                color: var(--ion-paid-color);
                font-size: 20px;
              }

              p {
                color: var(--ion-white-color);
                font-size: 14px;
              }
            }
          }
        }
      }

      .app_store {
        display: flex;
        align-items: center;

        .left_img,
        .right_img {
          width: 150px;
          height: 50px;
          margin-right: 20px;

          ion-icon {
            width: 100%;
            height: 100%;
            padding-right: 10px;
          }
        }
      }
    }

    .right {
      padding: 30px;
      width: 40%;

      .login_pages {
        background: transparent;
        background-image: unset;

        .google_sec {
          margin-bottom: 18px;
        }

        .card_bg {
          height: 100%;
          border-radius: 0;
        }

        .form_card {
          border-radius: 0;
          padding-top: 0;
        }

        .logo {
          display: none;
        }

        .sign_text {
          margin: 20px 0 0;

          h3,
          p {
            color: var(--ion-text-color);
          }
        }

        .header_boxs {
          .header_container_login {
            padding: 0;

            .login_segment {
              .tabs_head {
                ion-segment-button {
                  ion-label {
                    color: var(--ion-text-color);
                    font-weight: 800;
                  }

                  &.segment-button-checked {
                    &::after {
                      border-bottom: 3px solid var(--ion-theme-color);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /* Login Page design */

  ion-menu {
    &.eqi_sidemenu {
      width: 250px;

      ion-content {
        &::part(background) {
          background-image: unset;
        }
      }
    }
  }

  .menu_box {
    &.box_menu_list {
      &:hover {
        background-color: var(--ion-theme-color);
      }

      &.active {
        background-color: var(--ion-theme-color);
      }
    }

  }

  .btn_boxs {
    border-radius: 4px;
    cursor: pointer;
  }

  ngx-header,
  .ngx-header {
    ion-toolbar {
      .main_header_sec {
        .right_sec {
          ion-icon {
            cursor: pointer;
            font-size: 30px;
          }
        }
      }
    }
  }

  app-my-account-comp {
    .side_bar {
      .footer_sec {
        .menu_box {
          cursor: pointer;
        }
      }
    }
  }

  app-dashboard {
    .dashboard_container {
      margin-top: 50px;
      padding: 30px;
      overflow-y: scroll;

      .filter_container {
        padding: 20px;
        background: white;
        border: 1px solid #E9E9E9;
        border-radius: 4px;

        .btn_sec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0 30px;
          gap: 10px;

          .btn_boxs {
            margin: 0 0 0 auto;
            width: 160px;
            margin-bottom: 0;
            background-color: var(--ion-theme-color);
            height: 40px;
          }

          .input_sec {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px 10px;

            .btn_boxs {
              margin: 0;
              // margin-left: 15px;
            }

            .search_box {
              // margin-right: 15px;
              min-width: 270px;
            }

            app-date-selector,
            a24-range-date-picker {
              width: 100%;
              position: relative;

              .datepicker {
                border: 1px solid #e5e5e5 !important;
                border-radius: 5px;

                .date_selector {
                  height: 100%;

                  ion-datetime-button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 9999;
                    opacity: 0;

                    &::part(native) {
                      width: 100%;
                      height: 100%;
                      padding: 0;
                      margin: 0;
                    }
                  }

                  ion-row {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 !important;
                    height: 40px;
                    cursor: pointer;

                    ion-col {
                      font-size: 14px !important;
                      padding-left: 10px;

                      .picked_dates {
                        white-space: nowrap;

                        span {
                          padding-right: 15px;
                          color: var(--ion-text-color);
                        }
                      }

                      ion-icon {
                        display: flex;
                        margin: 0 8px 0 auto;
                        font-size: 12px !important;
                        width: 16px;
                        color: #b1b1b1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .list_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin: 15px 0;
        justify-content: space-between;
        flex-wrap: wrap;

        .list_item {
          width: 24%;
          background-color: var(--ion-white-color);
          height: 850px;
          border: 1px solid #E9E9E9;
          border-radius: 4px;
          position: relative;

          .header_list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 15px;
            color: var(--ion-text-color);
            border-bottom: 1px solid #dfdfdf;
            padding-bottom: 20px;

            .list_tittle {
              display: flex;
              align-items: center;

              ion-icon {
                font-size: 25px;
              }

              span {
                margin-left: 10px;
                font-size: 14px;
                font-weight: 600;
              }
            }

            .list_count {
              &.upcoming {
                .count_box {
                  background: var(--ion-upcoming-color);
                }
              }

              &.overdue {
                .count_box {
                  background: var(--ion-overdue-color);
                }
              }

              &.unpaid {
                .count_box {
                  background: var(--ion-unpaid-color);
                }
              }

              &.paid {
                .count_box {
                  background: var(--ion-paid-color);
                }
              }

              .count_box {
                padding: 0px 8px;
                background: var(--ion-upcoming-color);
                border-radius: 50px;
                font-size: 14px;
                color: var(--ion-white-color);
                margin-right: 10px;
              }
            }
          }

          .body_sec {
            padding: 0 10px 10px;
          }
        }
      }
    }
  }

  ion-card{
    ion-card-content{
      ion-item{
        .two_inputs{
          div, ion-input{
            height: 100%;
          }
        }
      } 
    } 
  } 

  app-add-bill{
    ion-content{
      form{
        padding-top: 30px;
      }
      .data_list{
        padding-bottom: 40px !important;
      }
    }
  }

  app-report{
    .data_list{
      margin-top: 90px !important;
    }
  } 

  app-add-bill,
  app-report,
  app-settings,
  app-clean-calc,
  app-emi-calc,
  app-currency-converter,
  app-compound-interest {
    form {
      height: auto;
      padding-top: 100px;
      position: relative;
      padding-bottom: 100px;
    }

    .data_list {
      max-width: 500px;
      margin: 0 auto 0;
      padding: 30px 15px 40px;
      height: 100%;
      // position: absolute;
      // top: 46%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }

  app-clean-calc,
  app-emi-calc,
  app-currency-converter,
  app-compound-interest {
    .data_list {

      &.calc_list {
        background: var(--ion-bg-theme);
        padding: 30px 35px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }

  app-about {
    .about_page {
      max-width: 500px;
      margin: 80px auto 0;
      padding: 30px 15px 40px;
      height: 80%;
    }
  }

  ion-footer {
    &.save_footer {
      justify-content: flex-end;
    }

    .left_sec,
    .right_sec {
      width: 185px !important;
    }
  }

  ngx-header,
  .ngx-header {
    ion-toolbar {
      border-bottom: 1px solid #E9E9E9;
      box-shadow: unset;
      border-radius: unset;
      --border-radius: unset;
    }
  }

}

.list_box,
a24-range-date-picker {
  cursor: pointer;
}

ion-card {
  ion-card-content {
    ion-item {
      a24-select-component {
        ion-select {
          width: 100%;

          &::part(text) {
            width: 100vw !important;
          }
        }
      }
    }
  }
}

.app-empty {
  ion-icon {
    width: 100%;
    height: 75px;
  }

  p {
    color: var(--ion-text-color);
    text-align: center;
  }
}

web-bill-list {
  .list_items {
    .list_card {
      .app-empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.category {
  .txts {
    p {
      color: var(--ion-text-color);
    }
  }
}

.list_box {
  .left_sec {
    .body_txt {
      color: var(--ion-text-color);

      .body_head {
        color: var(--ion-text-color);
      }
    }
  }
}

ion-modal {
  ionic-selectable-modal {
    ion-header {
      ion-toolbar {
        .toolbar-background {
          --box-shadow: unset;
        }
      }
    }
  }
}

.web {
  ion-alert {
    --min-width: 500px !important;
    --width: 100% !important;
  }
}


ion-modal {
  z-index: 20001;
  --width: 25%;
  --border-radius: 10px;
  --box-shadow: unset;
  height: auto;

  ion-datetime {
    max-width: 100% !important;
  }

  &.show-modal {
    .drop_down_top {
      .cancel_btn {
        color: #000;
        cursor: pointer;
      }
    }
  }

  &#invoice-signatureModal {
    align-items: baseline;

    &::part(content) {
      max-width: 341px;
      width: 100%;
      height: 342px;
    }
  }

  &::part(content) {
    max-width: 520px;
    width: 100%;
  }
}

.date_picker {
  // margin-right: 15px;
  min-width: 270px;
}

ion-modal {
  .model_container {
    .model_box {
      .modal_footer {
        .btn_box {
          ion-button {
            margin: 0;
            border-radius: 4px;

            &::part(native) {
              height: 100%;
              padding: 10px 20px !important;
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1450px) {

  app-dashboard {
    .dashboard_container {
      .list_container {
        .list_item {
          width: 48.5% !important;
        }
      }
    }
  }

}

@media only screen and (max-width: 768px) {

  .web {

    app-report{
      .data_list{
        margin-top: 0px !important;
        padding-top: 90px !important;
      }
    } 

    ion-alert {
      --min-width: 90% !important;
      --width: 100% !important;
    }
  }

  ion-modal::part(content) {
    max-width: 520px;
    width: 92%;
  }



  ion-menu.eqi_sidemenu {
    width: 100% !important;
  }

  app-about {
    .about_page {
      height: auto !important;
      margin-top: 0 !important;
    }
  }

  .chart_section {
    .empty_chart {
      width: 100% !important;
    }
  }

  app-add-bill {
    .data_list {
      height: 100%;
      margin: 0 auto 0 !important;
      padding: 0px 15px 90px !important;
    }
  }

  app-settings,
  app-add-bill,
  app-report,
  app-clean-calc {
    .data_list {
      height: 100%;
      margin: 55px auto 0 !important;
      padding: 30px 15px 90px !important;
      position: relative !important;
      top: unset !important;
      left: unset !important;
      transform: unset !important;
    }
  }

  ngx-header {
    ion-toolbar {
      .main_header_sec {
        .right_sec {
          .dhasbored_head {
            display: flex;
          }
        }
      }
    }
  }

  app-dashboard {
    .dashboard_container {
      padding: 0 10px !important;

      .list_container {
        .list_item {
          width: 100% !important;
        }
      }

      .filter_container {
        .btn_sec {
          flex-wrap: wrap;

          .input_sec {

            .search_box,
            .date_picker {
              width: 100%;
              min-width: 100%;
              margin-bottom: 10px;
              margin-right: 0 !important;
            }
          }

          .btn_boxs {
            width: 100% !important;
          }
        }
      }
    }
  }

}
@media only screen and (max-width: 1080px) {

  .ios {
    .web_login {
      height: -webkit-fill-available !important;

      .right {
        height: 100%;

        .card_bg {
          height: 100%;
        }
      }
    }
  }


}


@media only screen and (max-width: 767px) {

  .ios{
    .web_login{
      .right{
        height: 100vh;
      }
    } 
    .login_pages{
      .google_sec{
        ion-button{
          height: 55px;
        }
      } 
    } 
  } 

  app-calendar-overview{
    .empty_boxs{
      .list_card{
        height: 15vh;
        .app-empty{
          transform: translate(-50%, 10%);
          top: 0%;
          left: 40%;
        }
      }
    } 
  } 

  app-calendar-overview{
    .filter_container{
      .date_picker{
        .shown{
          .calendar{
            td{
              width: 11vw;
            }
          } 
        } 
      } 
    } 
  } 


  .date_pick_open{
    height: auto !important;
    ion-card{
      ion-card-content{
        ion-item{
          ngx-date-picker{
            min-height: 700px !important;
            height: 100% !important;
            .show-ranges{
              position: fixed !important;
            }
          }
        } 
      } 
    } 
  }

  .show-ranges {
    display: unset !important;
    width: 100vw !important;
    max-width: 100%;
    position: fixed !important;
    bottom: 0;
    padding-top: 60px !important;
    top: auto !important;
    left: auto !important;
    right: 0 !important;
    z-index: 99999 !important;
    contain: content !important;
    height: 100%;
    overflow-y: scroll !important;
    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 48%;
        font-size: 12px;
        padding: 5px;

        button {
          padding: 10px 16px;
          font-size: 12px;
          background: transparent !important;
          color: #000;
          border: 1px solid var(--ion-theme-color) !important;
          border-radius: 5px;
          width: 100%;

          &.active {
            background-color: var(--ion-theme-color) !important;

          }
        }
      }

    }

    .calendar {
      width: 98%;
      max-width: 100%;
      margin: 4px;
      display: block;
      max-width: 100% !important;

      .calendar-table {
        thead {
          tr:first-child {
            th {
              font-size: 16px;
              padding: 0;
              line-height: 24px;
              color: #404040;
              height: 15px;
            }
            th:nth-child(2){
              .dropdowns{
                width: 100px;
              }
            } 
          }
        }
      }

      tr {
        td {
          padding: 4px !important;
          white-space: nowrap;
          text-align: center;
          min-width: 30px;
          border-radius: 0;
          font-weight: 400 !important;
        }
      }

      .week-days {
        th {
          text-transform: uppercase;
          color: #404040;
          font-weight: 700;
          padding: 4px 8px !important;
          font-size: 12px;
        }
      }
    }
  }


  .web {
    app-clean-calc {
      ion-content {
        &::part(background) {
          background: #07090a;
        }
      }

      .data_list {
        top: 40px !important;
      }
    }
  }

  .empty_boxs {
    .list_card {
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .app-empty {
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
      }
    }
  }


  .menu_box {
    .item_left {
      width: 100%;

      .item_txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .count {
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1px 5px;
          border-radius: 20px;
          margin-right: 8px;

          &.upcome_btn {
            background: var(--ion-upcoming-color);
          }

          &.overdue_btn {
            background: var(--ion-overdue-color);
          }

          &.unpaid_btn {
            background: var(--ion-unpaid-color);
          }

          &.paid_btn {
            background: var(--ion-paid-color);
          }
        }
      }
    }
  }

  .web_login {
    .left {
      display: none !important;
    }
  }

  /* paid date picker */
 .app{
  &.date-modal{

    #bill-item-modal{
    
        display: none;
    
    }
    
    ion-popover{
    
      &#paymentDate{
    
        &::part(content){
          top: calc(44.5px + var(--offset-y, 0px)) !important;
        }
    
      }
    
    }
    
  }
 }  
  /* paid date picker */

  app-guide{
    .help-wrap{
        .faq{
            .faq_section{
                .faq_info{
                    ion-item{
                        youtube-player{
                            iframe{
                                width: 100%;
                                height: 100%;
                            }
                        } 
                    } 
                } 
            } 
        } 
    } 
  }

  app-calendar-overview{

    .data_list{
      height: auto;
    }
    
  }
}

@media only screen and (max-width: 500px) {
  .login_pages {
    .sign_text {
      margin: 10px 0;
    }
  }
}
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./assets/web.scss";

// fonts


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=REM:wght@400;700&display=swap');

*,
body {
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: none;
  scroll-behavior: smooth;
}

:root[mode="ios"],
:root[mode="md"] {
  --ion-font-family: 'Poppins', sans-serif !important;
  font-family: 'Poppins', sans-serif !important;
  --ion-default-font: 'Poppins', sans-serif !important;
}

// COMMON STYLES

::-webkit-scrollbar {
  padding: 0px;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: 1px solid transparent;
  border-width: 3px;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid transparent;
  width: 0;
  display: table-cell;
  vertical-align: middle;
  border-radius: 30px;
}


:root {
  --ion-theme-color: #875AFF;
  --ion-theme-light-bg: #ffffff;
  --ion-white-color: #fff;
  --ion-text-color: #000000;
  --ion-card-color: #ffffff;
  --ion-theme-white-color: #ffffff;
  --ion-paid-color: #2FAA58;
  --ion-unpaid-color: #FF6B00;
  --ion-upcoming-color: #407BFF;
  --ion-overdue-color: #E5001C;
  --ion-color-base: #875AFF !important;
  --ion-bg-theme: #080A0B;
  --ion-color-danger: #E5001C;
  --dark_btn: #080A0B;
  --white-bg: #ffffff;
  --liner_color: linear-gradient(180deg, #521594 0%, #000000 100%);
  --always-white: #ffffff;

  --ion-payable-border: #E5001C;
  --ion-receivable-border: #2FAA58;

  --ion-category-bg: #2FAA5810;

  --ion-background-color: #f7f7f7;
  --ion-backdrop-color: #000000;
  --ion-backdrop-opacity: 0.5;
  --ion-border-color: #d6d6d6;
  --ion-item-border-color: #d6d6d6;
  --ion-toolbar-background: #000000;
  --ion-toolbar-color: #fff;
  --ion-toolbar-segment-color: #171717;
  --ion-item-background: transparent;
  --ion-item-color: #000000;
  --ion-placeholder-color: #000000;
  --ion-hover-color: #f7cfc7;
  --ion-sidemenu-color: #fff;
  --ion-input-icon-color: #c9c6c6;
  --ion-input-bg-color: #f5f5f5;
  --border-focus-color: #171717;
  --ion-active-label-color: #9b9494;
  --ion-active-label-bg-color: #fdeeee;
  --ion-multi-select-bg-color: #f2f2f2;
  --ion-item-background-black: #707070;
  --ion-item-bb-text-label: #c4c4c4;
  --ion-icon-color: #9f9f9f;
  --ion-card-background-color: #1a1a1a;
  --ion-success-color: #03a55a;
  --ion-failed-color: #ffe01b;
  --ion-warring-color: #f21708;
  --ion-slider-nav-color: #d0d4ee;
  --ion-color-primary: #000000;
  --ion-theme-red-color: #d74637 !important;
  --ion-color-contrast: #fff !important
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

.ion-color-secondary {
  --ion-color-base: var(--ion-paid-color) !important;
}

.ion-color-primary {
  --ion-color-base: var(--ion-theme-color) !important;
}

.transparent_btn {
  color: var(--ion-theme-color);
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;

  &::part(native) {
    background-color: transparent;
    color: var(--ion-theme-color);
    box-shadow: none;
  }

  &.ion-activated {
    --background: transparent !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000 !important;

  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;

  transition: background-color 5000s ease-in-out 0s;
}

.ion-color-success {
  --ion-color-base: var(--ion-theme-color) !important;
  --ion-color-contrast: var(--ion-white-color) !important;
}

a24-button {
  ion-button {
    &::part(native) {
      background: transparent;
      color: var(--ion-white-color);
      border-radius: 5px;
      width: auto;
      font-size: 14px;
      box-shadow: unset;
      text-transform: capitalize;
      padding: 20px;
    }
  }

  &.save_btn {
    ion-button {
      &::part(native) {
        background: var(--ion-theme-color);
        color: var(--always-white);
      }
    }
  }
}

ion-popover {
  ion-select-popover {
    ion-list {
      padding: 0 !important;

      ion-radio-group {
        ion-item {
          &.item-radio-checked {
            --background: var(--ion-theme-color) !important;
            --background-focused: var(--ion-theme-color) !important;
            --background-focused-opacity: 0.2 !important;
            --background-hover: var(--ion-theme-color) !important;
            --background-hover-opacity: 0.12 !important;
            --color: var(--ion-white-color) !important;
          }

        }
      }
    }
  }
}


.hide-err {
  font-size: 12px;
  color: var(--ion-color-danger);
  letter-spacing: 0.4px;
}

ion-button {
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input::placeholder {
  text-transform: capitalize !important;
}


// COMMON STYLES

@import "/src/theme/responsive.scss";

.dark_btn {
  background-color: var(--ion-theme-color);
  color: var(--always-white);
}

p {
  margin: 0;
}

.btn_boxs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-theme-white-color);
  height: 45px;
  border-radius: 10px;
}

.btn_boxs ion-icon {
  font-size: 25px;
  padding-right: 10px;
}

.dark_btn ion-icon {
  filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(390%) hue-rotate(246deg) brightness(116%) contrast(100%);
}

.dark_btn p {
  color: var(--ion-white-color);
}

.btn_boxs p {
  margin: 0;
  font-size: 14px;
}

ion-action-sheet {
  .action-sheet-wrapper {
    .action-sheet-group {
      .action-sheet-selected {
        background-color: var(--ion-theme-color);
        color: var(--ion-white-color);
        font-weight: 500 !important;
      }
    }

    .action-sheet-group-cancel {
      display: none;
    }
  }
}

ion-popover {
  &#startDatetime {
    &::part(content) {
      left: 50% !important;
      --width: 310px;
      --max-height: 100%;
      top: 50%;
      transform: translate(-50%, -50%) !important;
      height: max-content;
    }
  }

}

.top_header {
  box-shadow: unset;
  border-radius: unset;
  --border-radius: unset;
}

/* side menu */

ion-menu {
  &::part(container) {
    width: 100%;
  }

  &.eqi_sidemenu {
    ion-content {
      &::part(background) {
        background: var(--ion-bg-theme);
        background-image: url(assets/svg/side_bg.svg);
        background-size: cover;
        width: 100%;
        background-position: initial;
      }
    }
  }
}

.menu_box {
  &.box_menu_list {
    &.active {
      background-color: var(--ion-theme-color);
    }
  }
}

app-my-account-comp {
  .side_bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    height: 100%;
    padding: 40px 0px;
    overflow-y: scroll;

    .head_sec {
      width: 100%;
      padding: 0;

      .head_box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 15px;

        .mail_profile {
          width: 60px;
          height: 60px;
          border-radius: 50px;
          overflow: hidden;

          &.txt_profile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: var(--ion-text-color);
            background-color: var(--ion-theme-color);
            font-weight: 700;
          }

          ion-avatar {
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              object-position: center;
            }
          }
        }

        .uesr_text {
          text-align: center;
          color: #fff;
          padding: 0 20px;
          width: 100%;

          ion-label {
            h3 {
              font-size: 16px;
              margin: 5px 0 15px;
            }

            h2 {
              font-size: 14px;
            }

            p {
              font-size: 12px;
              color: #b1b1b1;
              margin: 5px 0;
            }
          }

          .btn_boxs {
            margin: 0 auto;
            width: 160px;
            margin-bottom: 0;
            background-color: var(--ion-theme-color);
            height: 40px;
          }
        }
      }
    }

    .footer_sec {
      width: 100%;
      padding: 0;
    }
  }
}

.pwd_valid {
  background: #1e56e717;
  padding: 10px;
  color: var(--ion-color-danger);
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.6px;

  p {
    color: var(--ion-color-danger) !important;
    letter-spacing: 0.6px;
  }
}

.menu_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--ion-white-color);
  height: 43px;
  padding: 0 20px;
  margin: 0 0 10px;

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: var(--ion-white-color);
    text-decoration: none;
  }

  &.selected_item {
    background: var(--ion-theme-color);
    color: var(--ion-text-color);

    a {
      color: var(--ion-text-color);
    }

    cursor: pointer;

    .item_left {
      .item_icon {
        a24-avatar {
          ion-avatar {
            ion-icon {
              filter: brightness(0) saturate(100%);
            }
          }
        }
      }
    }
  }

  a24-avatar {
    ion-avatar {
      margin: 0;
      padding: 0;
      --border-radius: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .item_left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .item_icon {
      padding-right: 15px;

      a24-avatar {
        ion-avatar {
          ion-icon {
            font-size: 28px;
          }
        }
      }
    }

    .item_txt {
      a24-label {
        ion-label {
          line-height: 30px;
          margin: 0;
          padding: 0;
          font-size: 16px;
        }
      }
    }
  }
}

/* side menu */

/* all Header */

ngx-header,
.ngx-header {
  ion-toolbar {
    --background: unset;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    height: 60px;
    --min-height: 60px;
    box-shadow: 0px -5px 10px rgb(0 0 0 / 51%);
    border-radius: 0;
    --border-radius: 0;
    background: var(--ion-theme-white-color);

    &.no_transparent {
      .main_header_sec {
        .left_sec {
          .page_title {
            ion-buttons {
              ion-button {
                ion-icon {
                  filter: brightness(0) saturate(100%);
                  font-size: 34px;
                }
              }
            }
          }
        }

        .right_sec {
          .dark {
            display: block !important;
          }

          .light {
            display: none !important;
          }
        }
      }
    }

    &.header_transparent {
      --background: transparent !important;
      box-shadow: unset !important;
      border-radius: unset !important;
      background: transparent !important;

      .menu_icon {
        font-size: 34px;
      }

      .main_header_sec {
        padding: 0 0 0 10px !important;

        .right_sec {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          ion-icon {
            width: 100px;
            height: 50px;
          }
        }
      }
    }

    .main_header_sec {
      padding: 0 15px;
      display: flex;
      align-items: center;

      .left_sec {
        height: 100%;
        width: 75%;
        overflow: hidden;

        .page_title {
          display: flex;
          align-items: center;

          ion-buttons {
            margin-right: 15px;

            ion-button {
              --padding-start: 0;

              ion-icon {
                color: var(--ion-text-color);
              }
            }
          }

          ion-title {
            position: relative;
            padding-inline: 0px;
            color: var(--ion-text-color);

            .btn_box {
              text-align: left;

              .title_new_sec {
                font-size: 16px;
              }
            }
          }
        }
      }

      .right_sec {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 25%;

        .logo {
          width: 65px;
          height: 50px;
        }

        ion-icon {
          &.chart_icon {
            font-size: 28px;
          }
        }

        ion-icon {
          font-size: 24px;
          color: var(--ion-warring-color);
          margin-left: 12px;
          filter: brightness(0) saturate(100%) invert(43%) sepia(69%) saturate(4329%) hue-rotate(237deg) brightness(100%) contrast(103%);
        }
      }
    }
  }
}

.tabs_items_sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tab_gruop {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #875aff;
    height: 34px;
    width: 22%;
    background: #875aff;
    color: #fff;
    cursor: pointer;
  }
}

/* all Header */

app-ocr {
  .filepond--root {
    display: none;
  }

  .filepond {
    display: none;
  }
}

/* body */

.data_list {
  background-color: var(--ion-card-color);
  padding: 80px 15px 110px;
  height: 100%;
  width: 100%;
  // overflow-y: scroll;
  position: relative;

  &.date_pick_open {
    height: auto !important;

    ion-card {
      ion-card-content {
        ion-item {
          ngx-date-picker {
            min-height: 700px !important;
            height: 100% !important;

          }
        }
      }
    }
  }

  &.empty_boxs {
    .list_card {
      .card_container {
        width: 100%;
        margin-top: 80px;

        ion-skeleton-text {
          width: 100%;
        }
      }
    }
  }

  .list_items {


    &.no_category_skeleton {
      .list_card {
        .card_container {
          padding-bottom: 0 !important;
        }
      }
    }

    .list_card {
      .card_container {
        padding-bottom: 115px;
      }

    }
  }

  .search_box {
    margin-bottom: 15px;
  }

  .app-empty {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);

    ion-icon {
      width: 100%;
      height: 75px;
    }

    p {
      margin: 0;
      text-align: center;
      line-height: 27px;

      span {
        background: var(--ion-theme-color);
        padding: 4px 10px;

        border-radius: 4px;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }

  &.with_footer {
    padding: 80px 15px 140px !important;
  }

  &.invoice_list {
    padding: 80px 15px 0;
  }
}

/* body */


/* search button */

a24-search {
  .search_box {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 0 0 10px;
    background: #fdfdfd;
    border-radius: 5px;
    border: 1px solid #dedede;

    ion-icon {
      font-size: 20px;
      position: absolute;
      left: 10px;
    }

    ion-searchbar {
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding: 0 0 0 5px;
      height: 40px;
      --background: transparent;
      --border-radius: 0;
      width: 90%;
      margin-left: auto;
      border-radius: 5px;

      .searchbar-input-container {
        height: 100%;

        input {
          box-shadow: unset;
          padding-inline-start: 0 !important;
          padding-inline-end: 10px;
        }

        &:focus {
          ion-icon {
            display: none !important;
          }
        }

        ion-icon {
          display: none !important;
        }

        button {
          ion-icon {
            display: block;
            display: block !important;
            left: auto;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}

/* search button */

.caps::first-letter {
  text-transform: uppercase;
}

app-faq {
  a24-tabs {
    ion-tab-bar {
      top: 60px;
      background: var(--ion-white-color);
      justify-content: space-between;
      --border: 0;
      --background: transparent;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 70px;
      border-radius: 0;
      box-shadow: 0px -5px 10px rgb(0 0 0 / 65%);
    }
  }

}

// form footer disable

.footer_disable {
  ion-tabs {
    ion-fab {
      display: none;
    }

    ion-tab-bar {
      display: none;
    }
  }
}

/* all footer */

a24-tabs {
  ion-tab-bar {
    height: 60px;
    --border: unset;
    // box-shadow: 0px -5px 10px rgb(0 0 0 / 10%);
    --background: var(--ion-white-color);

    ion-tab-button {
      max-width: 100%;

      ion-icon {
        font-size: 18px;
      }

      a24-label {
        ion-label {

          color: var(--ion-text-color) !important;
        }
      }

      &.tab-selected {
        // background: var(--ion-theme-color);

        // ion-icon {
        //   filter: brightness(0) saturate(100%)
        // }
        a24-label {
          ion-label {
            color: var(--ion-text-color) !important;
            font-weight: 600;
          }
        }
      }

    }
  }
}

ion-footer {
  &::before {
    display: none;
  }

  box-shadow: 0px -5px 10px rgb(0 0 0 / 10%);

  &.save_footer {
    display: flex;
    align-items: center;
    padding: 0;
    position: fixed;
    bottom: 0;
    background: var(--ion-white-color);

    .left_sec,
    .right_sec {
      text-align: center;
      width: 50%;

      a24-button {
        &.preview {
          ion-button {
            &::part(native) {
              background: var(--ion-white-color);
              color: var(--ion-text-color);
            }
          }
        }

        ion-button {
          margin: 0 !important;
          width: 100%;
          --border-radius: 0 !important;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          --box-shadow: unset;

          &::part(native) {
            text-transform: capitalize;
            font-weight: 300;
            font-size: 18px;
            border-radius: 5px;
            background-color: var(--ion-theme-color);
            color: var(--ion-white-color);
            width: 100%;
          }
        }
      }
    }
  }
}

/* all footer */

/* footer design */

.fab-edit {
  ion-fab {
    ion-fab-button {
      margin-bottom: 125px !important;
    }
  }
}

ion-tabs {
  ion-fab {
    margin-bottom: env(safe-area-inset-bottom);

    /* fix notch ios*/
    ion-fab-button {
      --box-shadow: none;
      margin-bottom: 28px;
      --background: var(--dark_btn);
      --color: #000;
      --background-activated: var(--ion-theme-color);
      --background-focused: var(--ion-theme-color);
      --background-hover: var(--ion-theme-color);
      margin-left: 2px;

      ion-icon {
        color: var(--ion-white-color);
      }
    }
  }

  ion-tab-bar {
    --border: 0;
    --background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    border-radius: 20px 20px 0 0;
    align-items: flex-end;

    &:after {
      content: " ";
      width: 100%;
      bottom: 0;
      background: transparent;
      height: env(safe-area-inset-bottom);
      position: absolute;
    }

    ion-tab-button {
      --background: #fff;
      position: relative;
      height: 70px;
      box-shadow: 0px -8px 6px rgb(0 0 0 / 6%);

      &::part(native) {
        --padding-start: 7px;
        --padding-end: 4px;
      }

      &.tab-selected {
        &::after {
          content: "";
          width: 73%;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
          margin: auto 0;
          border-radius: 70px 70px 0 0;
          border-bottom: 5px solid var(--ion-theme-color);
        }

        label {

          color: #000;
        }
      }

      &.tab-selected.paid {
        &::after {
          border-bottom: 5px solid var(--ion-paid-color);
        }
      }

      &.tab-selected.unpaid {
        &::after {
          border-bottom: 5px solid var(--ion-unpaid-color);
        }
      }

      &.tab-selected.overdue {
        &::after {
          border-bottom: 5px solid var(--ion-overdue-color);
        }
      }

      &.tab-selected.upcoming {
        &::after {
          border-bottom: 5px solid var(--ion-upcoming-color);
        }
      }

      ion-icon {
        font-size: 23px !important;
        margin-top: 0;
      }

      a24-label {
        ion-label {
          font-size: 11px !important;
          padding-top: 3px;
        }
      }

      &:first-child {
        border-top-left-radius: 20px;
      }

      &:last-child {
        border-top-right-radius: 20px;
      }
    }

    ion-tab-button.comments {
      margin-right: 0px;
      border-top-right-radius: 0px;
    }

    ion-tab-button.notifs {
      margin-left: 0px;
      border-top-left-radius: 0px;
    }

    svg {
      width: auto;
      margin-top: 2px;

      path {
        fill: #fff;
      }
    }
  }
}

/* footer design */

/* footer tabs */

a24-tabs {
  .no-tabs {
    ion-tabs {
      a24-tabs {
        .footer_container {
          &.view-tabs {

            ion-fab,
            ion-tab-bar {
              display: flex;
            }
          }
        }
      }

      ion-fab {
        left: auto !important;
        right: 10px !important;
        bottom: 50px !important;
      }

      ion-tab-bar,
      ion-fab {
        display: none;
      }
    }
  }
}

a24-fab {
  ion-fab {
    display: block !important;

    ion-fab-button {
      &.ion-color-light {
        --ion-color-base: var(--ion-theme-color) !important;
      }
    }
  }
}

.ios {
  ion-tab-bar {
    bottom: -34px !important;
  }
}

/* footer tabs */

/* input filed */

ion-card {
  margin: 0;
  width: 100%;
  box-shadow: unset;
  --background: transparent;

  ion-card-content {
    box-shadow: unset;
    width: 100%;
    padding: 0 !important;

    .btn_box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .radio_txt {
      .label_txt {
        font-size: 14px !important;
        color: var(--ion-text-color);
        font-weight: 600;
        padding: 10px 10px 0;
      }

      ion-item {
        padding: 5px 10px;
      }
    }

    ion-item {
      background: var(--ion-white-color);
      padding: 10px;
      border-radius: 5px;
      --highlight-color-invalid: transparent;
      --ripple-color: transparent;


      .date_picker_boxs{
        padding: 0 !important;
    width: 100% !important;
    height: 40px;
    border: 1px solid #e5e5e5 !important;
    text-indent: 10px !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    caret-color: var(--ion-text-color) !important;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    input{
      position: absolute;
      width: 100vw;
      height: 100%;
      opacity: 0;
    }
      }

      &.report_ion_item {
        padding: 10px 0 !important;
      }

      ngx-date-picker {
        width: 100%;
      }

      .simple_input {
        width: 100%;
        position: relative;

        .currency {
          position: absolute;
          top: 8px;
          right: 15px;
        }
      }

      &.data_table_sec {
        table {
          font-size: 18px;
          border-collapse: collapse;
          width: 100%;

          th {
            padding: 8px 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 500;

            &:last-child {
              text-align: right;
            }
          }

          td {
            padding: 8px 20px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            word-break: break-all;

            &:last-child {
              text-align: right;
            }
          }


          thead {
            tr {
              background-color: var(--ion-theme-color);
              color: #fff;
            }

            th {
              width: 25%;
            }
          }

          tbody {
            tr {
              &:nth-child(odd) {
                background-color: #f8f9fa;
              }

              &:nth-child(odd) {
                background-color: #f8f9fa;
              }
            }
          }
        }

      }

      &.total_item {
        .row_item {
          width: 100%;

          ion-row {
            width: 100%;

            .col {
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }

        ion-row {
          width: 33%;

          .col {
            .txt_head {
              margin-bottom: 8px;
              font-weight: 600;
            }

            .item_value {
              padding: 0 !important;
              width: 100% !important;
              height: 40px;
              border: 1px solid #e5e5e5 !important;
              text-indent: 10px !important;
              border-radius: 4px !important;
              font-size: 14px !important;
              caret-color: var(--ion-text-color) !important;
              display: flex;
              align-items: center;

              p {
                padding: 0 10px 0 0;
              }
            }
          }
        }
      }

      ion-row {
        &.year_month {
          align-items: center;

          ion-col {
            .txt_data {
              margin-left: 10px;
            }
          }
        }

        &.date_btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          margin-top: 20px;

          &.no_gap {
            margin-top: 0;
          }

          a24-button {
            margin-left: 15px;

            &.reset_btn {
              ion-button {
                --background: var(--ion-overdue-color);
                background: var(--ion-overdue-color);
              }

            }

            ion-button {
              height: auto;
              border-radius: 4px;
              margin: 10px 0;

              &::part(native) {
                padding: 13px 25px !important;
              }
            }
          }
        }
      }

      &.category_box {
        a24-select-component {
          .custom_select {
            ionic-selectable {
              width: 100%;
              max-width: 100%;
              --padding-end: 10px;
              border: 1px solid #e5e5e5 !important;
              border-radius: 5px;
              height: 40px;
              --highlight-height: 0px !important;
              --padding-start: 10px;

              .ionic-selectable-inner {
                height: 100%;

                .ionic-selectable-value {
                  width: 58%;
                  padding: 8px 10px;

                  .ionic-selectable-value-item {
                    display: flex;
                    align-items: center;

                    .catergory-box {
                      width: 20px;
                      height: 18px;
                      border-radius: 2px;
                      margin-right: 15px;
                    }
                  }
                }

                .ionic-selectable-icon {
                  width: 30px;
                }
              }
            }
          }
        }
      }

      .two_inputs {
        width: 100%;
        display: flex;
        border: 1px solid #e5e5e5 !important;
        border-radius: 4px !important;

        a24-input {
          width: 20%;

          input {
            border: unset !important;
            position: relative;

            &::after {
              content: "";
              width: 1px;
              height: 80%;
              position: absolute;
              top: 0;
              right: 0;
              background-color: red;
            }
          }
        }

        a24-select-component {
          width: 80%;

          ion-select {
            border: unset !important;
            border-radius: 0;
          }
        }
      }

      &.logout_btn {
        color: var(--ion-warring-color);
        font-weight: 600;
      }

      ion-toggle {
        --background-checked: #e4e4e4;
        --handle-background-checked: var(--ion-theme-color);
        --track-background-checked: #d9d9d9 !important;
      }

      ion-button {
        height: 100%;
        --box-shadow: unset;
        width: 100%;
        text-transform: capitalize;
        font-weight: 300;
      }

      ion-radio-group {
        ion-item {
          padding: 0;
          margin: 0;

          ion-radio {
            margin-inline-end: 15px;
          }
        }
      }

      .two_input {
        width: 100%;
        position: relative;

        ion-icon {
          position: absolute;
          z-index: 9999;
          top: 10px;
          right: 10px;
          font-size: 20px;
          color: #b1b1b1;
        }
      }

      .three_input {
        position: relative;
        padding: 0 !important;
        width: 100% !important;
        height: 40px;
        border: 1px solid #e5e5e5 !important;
        text-indent: 10px !important;
        border-radius: 4px !important;
        font-size: 14px !important;
        caret-color: var(--ion-text-color) !important;

        ion-input {
          overflow: hidden;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 260px;

          input {
            padding: 0 !important;
            width: 100% !important;
            border: unset !important;
            border-radius: unset !important;
            font-size: 14px !important;
            caret-color: var(--ion-text-color) !important;
          }
        }

        input {
          text-indent: 35px !important;
        }

        ion-icon {
          &.start {
            position: absolute;
            z-index: 9999;
            top: 10px;
            left: 10px;
            font-size: 20px;
            color: var(--ion-theme-color);
          }

          &.end {
            position: absolute;
            z-index: 9999;
            top: 10px;
            right: 10px;
            font-size: 20px;
            color: #b1b1b1;
          }
        }


      }

      &.check_box_item {
        a24-check-box {
          width: 100%;

          ion-list {
            padding-top: 0;
            padding-bottom: 0;

            ion-item {
              padding: 0;
              margin: 0;
              border-radius: 0;

              ion-checkbox {
                margin: 0px;
                --background-checked: #171717;
                --border-color-checked: #171717;
                --checkmark-color: #000;
              }

              a24-label {
                margin-bottom: 0;
                margin-left: 10px;

                ion-label {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }

      &.upload_logo {
        &::part(native) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      a24-text-area {
        ion-textarea {
          padding: 0 !important;
          margin: 0 !important;

          textarea {
            padding-top: 0 !important;
            font-size: 16px !important;
          }
        }
      }

      &.tax_rate {
        border-radius: 5px 5px 0 0;
        margin-bottom: 0;
      }

      .two_side_input {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        .left,
        .right {
          width: 48%;
        }
      }

      app-date-selector,
      a24-range-date-picker {
        width: 100%;
        position: relative;
        cursor: pointer;

        .datepicker {
          border: 1px solid #e5e5e5 !important;
          border-radius: 5px;

          .date_selector {
            height: 100%;

            ion-datetime-button {
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 9999;
              opacity: 0;

              &::part(native) {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
              }
            }

            ion-row {
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
              margin: 0 !important;
              height: 40px;

              ion-col {
                font-size: 14px !important;
                padding-left: 10px;

                .picked_dates {
                  white-space: nowrap;

                  span {
                    padding-right: 15px;
                  }
                }

                ion-icon {
                  display: flex;
                  margin: 0 8px 0 auto;
                  font-size: 12px !important;
                  width: 16px;
                  color: #b1b1b1;
                }
              }
            }
          }
        }
      }

      &::part(native) {
        padding: 0;
        border: unset !important;
        --inner-border-width: 0px 0px 0 0px;
        --inner-padding-end: 0;
        --show-full-highlight: 0 !important;
        --highlight-background: unset;
        --background-activated-opacity: 0 !important;
        --background-focused-opacity: 0 !important;
        --background-hover-opacity: 0 !important;
        --background-activated: transparent;
        --background-focused: transparent;
        --background-hover: transparent;
      }

      .photo-field {
        width: 100%;
        background: #fffdf1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 2px dashed var(--ion-theme-color);
        height: 100px;
        border-radius: 5px;

        ion-card {
          padding: 0 !important;

          ion-icon {
            font-size: 35px;
          }

          p {
            color: var(--ion-text-color);
            font-size: 12px;

          }
        }
      }

      a24-label {
        margin-bottom: 8px;
        font-weight: 600;

        ion-label {
          margin: 2px 0;

          font-size: 14px !important;
          transform: unset !important;
          --highlight-color-invalid: none !important;
        }
      }

      a24-select-component {
        width: 100%;

        ion-select {
          width: 100%;
          max-width: 100%;
          --padding-end: 10px;
          border: 1px solid #e5e5e5 !important;
          border-radius: 5px;
          height: 40px;
          --highlight-height: 0px !important;
          --padding-start: 10px;
          height: 45px;
          min-height: 100%;

          &::part(icon) {
            top: 0 !important;
            right: 12px;
          }

          &.drop_down.md {
            &::part(icon) {
              top: 0 !important;
            }
          }

          &.md {
            &::part(icon) {
              top: 10px !important;
              right: 0;
            }
          }


        }
      }

      a24-input {
        width: 100%;
        --highlight-height: 0px !important;
        --show-full-highlight: 0;
        --highlight-color-invalid: transparent;

        &.two_input {
          margin-top: 10px;
        }

        ion-input {
          input {
            padding: 0 !important;
            width: 100% !important;
            height: 40px;
            border: 1px solid #e5e5e5 !important;
            text-indent: 10px !important;
            border-radius: 4px !important;
            font-size: 14px !important;
            caret-color: var(--ion-text-color) !important;
          }
        }
      }
    }
  }
}

/* input filed */

.login_pages {
  background: var(--ion-bg-theme);
  height: 100%;
  background-image: url(assets/svg/home_bg.svg);
  background-size: contain;
  position: relative;
  width: 100%;
  background-position: initial;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--ion-white-color);

  .header_boxs {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .header_container_login {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      .login_segment {
        .tabs_head {
          ion-segment-button {
            --indicator-color: transparent;
            --border-color: unset;
            --indicator-box-shadow: none;
            --border-radius: unset;

            ion-label {
              font-size: 15px;
              color: var(--ion-white-color);
              text-transform: capitalize;
            }

            &.segment-button-checked {
              &::after {
                position: absolute;
                content: "";
                border-bottom: 1px solid var(--ion-theme-color);
                z-index: -1;
                width: 40%;
                display: block;
                bottom: 0;
                margin-left: auto;
                right: 30%;
                left: 0;
                transition: 500ms linear;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right_icon {
      ion-icon {
        width: 100px;
        height: 47px;
      }
    }

    ion-icon {
      width: 30px;
      height: 30px;
      margin: 0 auto;
      display: block;
    }
  }

  .page_svg {
    ion-icon {
      width: 100%;
      margin: 20px auto;
      height: 230px;
    }
  }

  .form_card {
    background: var(--ion-white-color);
    border-radius: 25px;
    padding-top: 30px;
  }

  .sign_text {
    text-align: left;
    padding: 10px 20px;
    margin: 35px 0;

    h3 {
      margin: 0px 0px 10px 0px;
      font-size: 23px;
    }

    p {
      margin: 18px 0px 10px 0px;
      font-size: 14px;
      color: var(--ion-white-color);
    }
  }

  .card_bg {
    height: 70%;
    position: relative;
    width: 100%;
    z-index: 9;
    border-radius: 30px 30px 0 0;
    margin-bottom: 0;
    padding: 10px 20px;

    form {
      ion-card {
        background: transparent;
        margin: 0 !important;
        box-shadow: unset;

        ion-card-content {
          padding: 0 !important;

          ion-list {
            color: var(--ion-white-color);

            .field {
              position: relative;

              ion-item {
                a24-label {
                  padding-bottom: 0;
                  margin-bottom: 0;
                }
              }

              .password_icon {
                position: absolute;
                top: 33px;
                right: 10px;
                z-index: 999;

                ion-icon {
                  color: var(--ion-text-color);
                }
              }

              ion-item {
                padding: 0;
                margin-bottom: 10px;

                a24-label {
                  ion-label {
                    --color: var(--ion-text-color) !important;
                    font-weight: 500;
                    --background: transparent;
                    color: var(--ion-text-color) !important;
                    margin-bottom: 0;
                  }
                }

                a24-input {
                  ion-input {
                    background: transparent;
                    --background: transparent;
                    height: 35px;

                    --padding-bottom: 20px !important;

                    input {
                      color: var(--ion-text-color) !important;
                      height: 100%;
                      border: unset !important;
                      border-bottom: 1px solid #cecece !important;
                      text-indent: 0 !important;
                      border-radius: 0 !important;
                      caret-color: #000;
                    }
                  }
                }
              }

            }
          }

          .submit_sec {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 15px 0px;
          }
        }
      }

      &.qi-item-invalid {
        .ion-invalid {
          input {
            border-bottom: 1px solid var(--ion-theme-red-color) !important;
          }
        }

      }

    }

    .continue-with {
      text-align: center;
      width: 100%;
      display: block;

      p {
        font-size: 14px;
        color: var(--ion-text-color);
        margin: 20px 0;
        position: relative;

        &::after {
          content: " ";
          height: 1px;
          width: 30px;
          background: #5a5a5a;
          display: block;
          position: absolute;
          top: 50%;
          left: 15%;
        }

        &::before {
          content: " ";
          height: 1px;
          width: 30px;
          background: #5a5a5a;
          display: block;
          position: absolute;
          top: 50%;
          right: 15%;
        }
      }

      .social_login {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        span {
          background: var(--ion-theme-color);
          display: block;
          margin: 0 15px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          line-height: 40px;
          position: relative;

          ion-icon {
            width: 22px;
            height: 22px;
            display: block;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            color: var(--ion-text-color);
          }
        }
      }
    }
  }

  .forget_pw {
    color: var(--ion-text-color);
    display: block;
    text-align: right;

    font-size: 14px;
  }

  .forget_sec {
    display: flex;
    justify-content: center;

    ion-button {
      clear: both;
      color: var(--ion-text-color);
      width: 100%;
      --border-radius: 5px;
      height: 40px;
      margin: 0;

      &::part(native) {
        background: var(--ion-theme-color);
        box-shadow: unset;
        text-transform: capitalize;
        font-size: 16px;
        color: var(--ion-white-color);
        font-weight: 400;
      }
    }
  }

  .google_sec {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;

    ion-button {
      clear: both;
      color: var(--ion-text-color);
      width: 100%;
      --border-radius: 5px;
      height: 45px;
      border: 1px solid #000;
      border-radius: 5px;
      margin: 0;

      ion-icon {
        padding-right: 15px;
      }

      &::part(native) {
        background-color: white;
        box-shadow: unset;
        text-transform: capitalize;
        font-size: 16px;
      }
    }
  }

  .signup_text {
    text-align: center;
    padding: 15px 0;

    p {
      a {
        color: var(--ion-text-color);
      }
    }
  }
}


.card_bg {
  background-color: var(--ion-card-color);
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 10px 0;

  ion-reorder-group {
    ion-skeleton-text {
      margin: 5px auto 10px;
      width: 92%;
      height: 50px;
    }
  }

  &.currency {
    .currency_box {
      position: relative;

      ion-item {
        padding-right: 15px;
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .right_sec {
        width: 100%;

        .icon_sec {
          p {
            width: 120px;
            margin-left: auto;
          }
        }
      }
    }
  }

  &.item_bg {
    padding-bottom: 0;
  }

  &.head_sec {
    .card_box {
      height: 100%;
    }
  }

  .card_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 50px;

    ion-item {
      --inner-border-width: 0;
      --padding-start: 0;
      --inner-padding-end: 0;
      --border-width: 0;
      --show-full-highlight: 0;
      --border-color: unset;

      &.left_sec {
        width: 100%;
        --padding: 0;
        --min-height: auto;

        ion-label {
          margin: 0;

          font-size: 14px;
          transform: unset !important;
        }

        &::part(native) {
          padding-inline-start: unset;
          padding-inline-end: unset;
          width: 100%;
        }

        .drop_box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;

          .drop_text {
            display: flex;
            align-items: center;
            width: 100%;
          }

          .drop_input {
            position: absolute;
            width: 100%;

            a24-select-component {
              --padding-start: 0;
              --inner-padding-end: 0;
              --inner-border-width: 0;
              --background: transparent;
              --min-height: 100%;
              opacity: 0;

              ion-select {
                max-width: 100%;

                &::part(text) {
                  visibility: hidden;
                }

                &::part(icon) {
                  visibility: hidden;
                }
              }

              ionic-selectable {
                max-width: 100%;

                .ionic-selectable-inner {
                  .ionic-selectable-value {
                    .ionic-selectable-value-item {
                      visibility: hidden;
                    }
                  }
                }

                .ionic-selectable-icon {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .left_sec {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.extra_text {
        width: 80%;

        .text_box {
          max-width: 50%;
        }

        .sub_box {
          max-width: 50%;

          span {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            word-break: break-all;
            padding-right: 10px;
          }
        }
      }

      .text_sec {
        h1 {
          margin: 0;
          font-size: 20px;

          overflow: hidden;
          width: 275px;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }

        p {

          font-size: 12px !important;
          margin: 5px 0;
          color: #858585;
        }
      }

      ion-icon {
        margin-right: 10px;
        font-size: 23px;
        color: var(--ion-text-color);
      }

      p {
        margin: 0;

        font-size: 14px;

        .item_dot {
          padding: 3px 8px;
          background: var(--ion-theme-color);
          border-radius: 50%;
          width: auto;
          margin-left: 5px;
        }
      }

      .text_des {
        p {
          margin: 0;
        }

        &.side_des {
          .sub_text {
            width: auto;
          }
        }

        .sub_text {
          font-size: 10px !important;

          width: 245px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .right_sec {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.text_full_view {
        width: 50% !important;
      }

      &.text_65 {
        width: 65% !important;
      }

      &.show_value {
        width: 50% !important;
      }

      ion-icon {
        color: var(--ion-text-color);
      }

      .icon_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        p {
          margin: 0;
          font-size: 12px;

          padding-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-align: right;
          display: block;
          text-overflow: ellipsis;
          width: 100%;
        }

        ion-icon {
          width: 20px;
          text-align: end;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: var(--ion-text-color);
        }
      }
    }
  }

  ion-reorder-group {
    .item_card {
      height: auto;
      min-height: 50px;
      align-items: stretch;
      border-radius: 4px;

      &.reorder-selected {
        box-shadow: unset !important;
        opacity: unset !important;
      }

      ion-reorder {
        display: flex;
        align-items: center;
      }

      .total_boxs {
        width: 92%;
        background: var(--ion-theme-light-bg);
        border-radius: 4px;
      }

      .full_view {
        display: flex;
        width: 100%;

        .action {
          align-items: center;
          justify-content: space-between;
          width: 40%;
          padding: 5px 15px !important;
          background: var(--ion-theme-color);
          font-size: 14px;


          p {
            font-size: 14px;

            padding: 0;
          }
        }

        &.item_name {
          .left_item {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            p {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
            }
          }

          p {
            padding: 10px 10px 5px;

          }
        }

        &.dis,
        &.tax {
          p {
            padding: 1px 10px;

            &.sub_txt {
              font-size: 10px;
            }
          }
        }

        p {
          padding: 10px;
        }

        &.box {
          margin-top: 10px;

          .right_item {
            width: 100%;
            background-color: var(--ion-text-color);
            border-radius: 0px 0px 4px 0;

            p {
              color: var(--ion-white-color);
            }
          }
        }
      }

      .left_item,
      .right_item {
        border-radius: 0;
        padding: 0 !important;
      }

      .left_item {
        height: auto;
        background: transparent;
      }

      .right_item {
        height: auto;
        justify-content: flex-start;
        background: transparent;
      }
    }
  }


  .item_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 15px;
    height: 50px;

    &.tax_list {
      height: auto;
      margin-bottom: 0;
      margin-top: 10px;

      .full_item {
        margin-bottom: 10px;
        border-radius: 4px;
      }
    }

    &.sub_total {
      .right_item {
        background: var(--ion-theme-color);

        p {
          font-size: 14px;
          color: var(--ion-text-color);
        }
      }

      .left_item {
        align-items: center;

        p {
          font-size: 14px;
        }
      }
    }

    &.full_card {
      flex-direction: column;

      .full_item {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text_full,
        .right_sec {
          color: var(--ion-text-color);
        }
      }
    }

    .left_item {
      display: flex;
      align-items: flex-start;
      width: 65%;
      background: var(--ion-theme-light-bg);
      padding: 10px;
      height: 100%;
      border-radius: 4px 0px 0px 4px;

      p {
        margin: 0;
        font-size: 12px;

      }
    }

    .right_item {
      width: 45%;
      background: var(--ion-theme-color);
      text-align: end;
      padding: 5px 10px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;
      flex-direction: column;
      border-radius: 0px 4px 4px 0px;
      word-break: break-word;

      p {
        margin: 0;
        font-size: 12px;

      }

      .sub_text {

        font-size: 12px;
      }
    }
  }

  .add_btn {
    background: var(--ion-theme-light-bg);
    margin: 0 15px 15px;
    border-radius: 4px;
  }

  .total_box {
    margin: 0;
    background-color: var(--ion-theme-color);
    border-radius: 5px;

    .left_sec,
    .right_sec {
      p {
        font-size: 18px;

        margin: 0;
      }
    }
  }
}

/* home page list data */

// .title_container {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   margin: 15px 0px;

//   .left,
//   .right {
//     display: flex;
//     align-items: center;

//     .value {
//       background: transparent;
//       padding: 0px 5px;
//       border: 1px solid var(--ion-upcoming-color);
//       color: var(--ion-upcoming-color);
//       border-radius: 35px;
//       font-size: 14px;
//     }

//     .txt {
//       padding-left: 7px;
//       font-size: 15px;
//       font-weight: 500;
//     }
//   }

//   .right {
//     .value {
//       border: 1px solid var(--ion-overdue-color);
//       color: var(--ion-overdue-color);
//     }
//   }
// }

.category {
  border: 1px solid var(--ion-category-border);
  border-radius: 40px;
  background: var(--ion-category-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  width: max-content;
  margin: 0 3px 0 auto;

  .txts {
    width: auto;
    max-width: 80px;

    p {
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
      padding-right: 5px;
    }
  }

  .dot {
    &.payable {
      background: var(--ion-payable-border);
    }

    &.receivable {
      background: var(--ion-receivable-border);
    }

    width: 8px;
    height: 8px;
    border-radius: 40px;
  }
}

.list_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  overflow: hidden;
  margin: 7px 0 -4px;
  padding: 0;
  border-bottom: 1px solid #dfdfdf;

  .price p {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 15px;
  }

  &.refresh {
    .left_sec {
      .body_txt {
        .two_box {
          ion-icon {
            display: initial;
          }
        }
      }
    }
  }

  &.warring_txt {
    .left_sec {
      .body_txt {
        .body_sub {
          color: var(--ion-overdue-color);
        }
      }
    }
  }

  &.paid {
    .left_sec {
      .calender_container {
        background: var(--ion-paid-color);
      }
    }

    .right_sec {
      .price {
        p {
          color: var(--ion-paid-color);
        }
      }
    }
  }

  &.unpaid {
    .left_sec {
      .calender_container {
        background: var(--ion-unpaid-color);
      }
    }

    .right_sec {
      .price {
        p {
          color: var(--ion-unpaid-color);
        }
      }
    }
  }

  &.upcoming {
    .left_sec {
      .calender_container {
        background: var(--ion-upcoming-color);
      }
    }

    .right_sec {
      .price {
        p {
          color: var(--ion-upcoming-color);
        }
      }
    }
  }

  &.overdue {
    .left_sec {
      .calender_container {
        background: var(--ion-overdue-color);
      }
    }

    .right_sec {
      .price {
        p {
          color: var(--ion-overdue-color);
        }
      }
    }
  }


  .left_sec {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 55%;

    .calender_container {
      width: 55px;
      height: 55px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
      color: var(--always-white);
      font-weight: 600;

      .head_txt {
        font-size: 16px;
        line-height: 16px;
      }

      .sub_txt {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .body_txt {
      padding-left: 10px;
      width: 70%;

      .two_box {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .boxs {
          width: auto;
          max-width: 85%;

        }

        ion-icon {
          display: none;
        }
      }

      .body_head {
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      ion-icon {
        font-size: 20px;
        margin-left: 5px;
        color: #39268E;
      }

      .body_sub {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }

  }

  .right_sec {
    width: 45%;
    text-align: end;
  }
}

/* home page list data */

/* draggable slider */

a24-draggable-slider {
  .wrapper {
    padding: 0 0 15px;
    position: relative;
    overflow-x: hidden;
    max-width: 100%;
    background: transparent;

    .icon {
      position: absolute;
      top: 0;
      height: 100%;
      width: 120px;
      display: flex;
      align-items: center;

      &:first-child {
        left: 0;
        display: none;
        background: linear-gradient(90deg, #fff 70%, transparent);

        i {
          margin-left: 15px;
        }
      }

      &:last-child {
        right: 0;
        justify-content: flex-end;
        background: linear-gradient(-90deg, #fff 70%, transparent);

        i {
          margin-right: 15px;
        }
      }

      i {
        width: 55px;
        height: 55px;
        cursor: pointer;
        font-size: 1.2rem;
        text-align: center;
        line-height: 55px;
        border-radius: 50%;

        &:hover {
          background: #efedfb;
        }
      }
    }

    .tabs-box {
      display: flex;
      gap: 12px;
      list-style: none;
      overflow-x: hidden;
      scroll-behavior: smooth;
      justify-content: space-between;

      .tab {
        cursor: pointer;
        font-size: 15px;
        white-space: nowrap;
        background: #ffffff;
        padding: 3px 12px;
        border-radius: 30px;
        color: var(--ion-text-color);
        width: auto;

        // &:hover{
        //   background: var(--ion-theme-color);
        //   font-weight: 700;
        // }
        &.active {
          background: var(--ion-theme-color) !important;
          border: 1px solid var(--ion-theme-color) !important;
          color: var(--ion-white-color);
        }
      }

      &.dragging {
        scroll-behavior: auto !important;
        cursor: grab !important;

        .tab {
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }
}

/* draggable slider */

/* Chart */

ion-modal {
  &.range_date_picker {
    --width: 90%;
    --height: auto;
    --border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.20);

    ion-title {
      color: var(--ion-theme-color);
      font-size: 18px;
      padding: 10px;
    }
  }

}

a24-ion-datepicker {
  width: 100%;

  .form_group {
    .show_time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      --padding-end: 10px;
      border: 1px solid #e5e5e5 !important;
      border-radius: 5px;
      height: 40px;
      --highlight-height: 0px !important;
      padding: 0 10px;
    }
  }
}

.chart_section {
  margin-bottom: 20px;

  .empty_chart {
    position: relative;
    width: max-content;
    margin: 0 auto;

    .chart_txt {
      position: absolute;
      top: 72%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 86%;
      text-align: center;
      font-size: 14px;
      color: #868582;
      font-weight: 500;
    }
  }


}

/* Chart */

/* report page */

app-report {

  .data_list.date_pick_open {
    height: 100% !important;
  }

  .report_page_date {
    .show-ranges {
      z-index: 99999 !important;
      contain: content;
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      width: max-content !important;
      padding: 15px 15px 0;

      .calendar-table {
        thead {
          tr:first-child {
            th {
              font-size: 16px;
              padding: 0;
              line-height: 24px;
              color: #404040;
              height: 15px;
            }

            th:nth-child(2) {
              .dropdowns {
                width: 100px;
              }
            }
          }
        }
      }

      .ranges {
        ul {
          li {
            button {
              color: var(--ion-text-color);
              &.active{
                color: var(--always-white) !important;
              }
            }
          }
        }
      }
    }
  }

  ion-card {
    ion-card-content {
      ion-item {
        a24-select-component {
          ion-select {

            &.md {
              &::part(icon) {
                top: 0 !important;
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/* report page */


app-about {
  .about_page {
    padding: 60px 10px 0;
    height: auto !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    margin-top: 60px !important;

    .btns {
      width: auto;
      height: 40px;
      padding: 5px 10px;
      border: 1px solid var(--ion-text-color);
      border-radius: 4px;
      cursor: pointer;

      .boxs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        p {
          padding-right: 20px;
          font-size: 14px;
        }
      }
    }

    h1 {
      margin: 0;
    }

    .car_box {
      width: 100%;
      height: auto;
      padding: 15px;
      border-radius: 5px;
      background: var(--ion-theme-color);
      color: var(--ion-white-color);

      h4,
      h5 {
        margin: 0 0 15px;

      }

      a {
        text-decoration: unset;
        color: var(--ion-white-color);
        font-size: 12px;
      }

      h4 {
        font-size: 18px;
      }

      h5 {
        font-size: 14px;
      }

      .two_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0;

        ion-icon {
          // color: #FF9800;
          color: var(--ion-white-color);
          margin-right: 7px;
        }

        .btns {
          border: 1px solid var(--ion-white-color);

          .boxs {
            p {
              color: var(--ion-white-color);
              font-size: 14px;
            }
          }
        }
      }
    }

    .content,
    .car_box {
      margin: 10px 0;
    }

    .content {
      p {
        font-size: 14px;
      }
    }

    .image_box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .image {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 12px;
        color: #6D6D6D;

        ion-icon {
          width: 70px;
          height: 70px;
        }
      }

    }
  }
}

ion-datetime {
  color: #000
}

.range_date_picker {
  ion-button {
    --background: var(--ion-theme-color) !important;
  }
}

/* FAQ */

.whats_new {


  /* Styling */
  .timeline {
    margin: 85px auto;
    position: relative;
  }

  .timeline:before {
    background-color: var(--ion-text-color);
    content: "";
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 40px;
    width: 2px;
    height: 100%;
  }

  .timeline-event {
    position: relative;
    margin-bottom: 20px;
  }

  .timeline-event:hover .timeline-event-icon {
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: var(--ion-theme-color);
  }

  .timeline-event:hover .timeline-event-thumbnail {
    -moz-box-shadow: inset 40em 0 0 0 var(--ion-theme-color);
    -webkit-box-shadow: inset 40em 0 0 0 var(--ion-theme-color);
    box-shadow: inset 40em 0 0 0 var(--ion-theme-color);
    color: var(--ion-white-color);

  }

  .timeline-event-copy {
    padding: 0 30px;
    position: relative;
    top: 0;
    left: 9px;
    width: 100%;
  }

  .timeline-event-copy strong {
    font-weight: 300;
  }

  .timeline-event-icon {
    -moz-transition: -moz-transform 0.2s ease-in;
    -o-transition: -o-transform 0.2s ease-in;
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: var(--ion-bg-theme);
    outline: 10px solid var(--ion-white-color);
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    box-shadow: 0px 3px 28px rgb(0 0 0);
  }

  .low_txt {
    margin: 5px 0 0;
    font-size: 12px;
    color: #7c7c7c;
  }

  .text_des {
    padding: 0;
    color: var(--ion-text-color);
    list-style: disc;
    margin-top: 10px;

    li {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .timeline-event-thumbnail {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: white;
    font-size: 0.75em;
    background-color: var(--ion-bg-theme);
    -moz-box-shadow: inset 0 0 0 0em #d8ef5a;
    -webkit-box-shadow: inset 0 0 0 0em #d8ef5a;
    box-shadow: inset 0 0 0 0em #d8ef5a;
    display: inline-block;
    padding: 0.25em 1em 0.2em 1em;
    margin: 8px 0 0;
  }


}

app-faq {
  a24-tabs {
    ion-tab-bar {
      top: 60px;
      background: var(--ion-white-color);
      justify-content: space-between;
      --border: 0;
      --background: transparent;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 70px;
      border-radius: 0;
      box-shadow: 0px -5px 10px rgb(0 0 0 / 65%);
    }
  }

}

.faq_list {
  padding: 150px 15px 50px;

  .according_container {
    ion-accordion-group {
      ion-accordion {
        &.accordion-expanded {
          box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.11);
          margin: 10px 0 10px;
          border: unset;
          --border-width: 0;
          padding: 0;
          padding: 0;

          ion-item {
            &::part(native) {
              background: var(--ion-theme-color);
            }
          }

          .tittle {
            padding: 0;
          }
        }

        .info_area {
          padding: 0 10px 10px;

          .body {
            p {
              margin: 0;
              font-size: 14px;
              padding-bottom: 15px;
            }

            youtube-player {
              iframe {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        ion-item {
          --padding-start: 0;
          --inner-padding-end: 0;

          &::part(native) {
            padding: 0 10px;
            font-size: 16px;
            font-weight: 600;
            text-transform: capitalize;
          }

          .tittle {
            padding: 20px 0;

            ion-label {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 280px;
            }
          }

          ion-icon {
            font-size: 24px;
            color: #333333;
            margin-inline: 5px;
          }
        }
      }
    }
  }
}

app-faqs {

  .version_3 {
    ion-accordion-group {
      ion-accordion {
        ion-item {
          &::part(native) {
            border-bottom: 1px solid #7288a2;
          }

          .tittle {
            ion-label {
              color: #e73814 !important;
              padding-left: 15px;
            }
          }

          ion-icon {
            color: #e73814 !important;
          }
        }

        .info_area {
          padding: 10px 15px !important;
        }
      }
    }
  }
}

a24-text-area {
  ion-textarea {
    padding: 0 !important;
    margin: 0 !important;

    textarea {
      padding-top: 0 !important;
      font-size: 16px !important;
    }
  }
}

a24-text-area {
  background: var(--ion-theme-light-bg);
  border-radius: 5px;
  padding: 10px;
  margin-top: 8px;

  ion-textarea {
    --padding-top: 0;
    margin-top: 0;

    textarea {
      font-size: 16px;
      min-height: 60px;
      --padding-end: 0;
      font-weight: 500 !important;
    }

    .textarea-wrapper {
      &::after {
        display: none;
      }
    }
  }


}

.errlength-count {
  text-align: end;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 5px;
  border-radius: 22px;
  font-size: 10px;
  font-weight: 500;
  margin-left: auto;
  margin-top: 5px;
}



app-feedback {
  form {
    height: 100%;
  }
}

/* FAQ */


app-ion-date-time-picker {
  ion-header {
    .header_txt {
      padding: 10px;
      font-size: 14px;
    }

    &::after {
      background-image: unset !important;
    }
  }
}

ion-alert {
  --min-width: 90% !important;
  --width: 100% !important;

  &.edit_bill {
    .alert-button-group {
      display: flex;
      gap: 10px;
      padding: 10px;


      button {
        width: auto;
        margin-left: 0 !important;
      }
    }
  }

  .alert-button-group {
    &:empty {
      button {
        display: none;
      }
    }

    button {
      &.alert-button-role-cancel {
        background-color: transparent !important;
        color: var(--ion-theme-color) !important;
      }

      margin: 0 !important;
      width: 30%;
      --border-radius: 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 10px 35px;
      --box-shadow: unset;
      background-color: var(--ion-theme-color) !important;
      color: var(--ion-white-color) !important;
      margin-left: 20px !important;

      .alert-button-inner {
        justify-content: center !important;
      }
    }
  }
}

//searchable dropdown

.tips {
  width: 100%;
  margin: 0 auto;
  background: #ffca0014;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  line-height: 20px;
  color: #875aff;
  margin-top: 10px;
}

a24-select-searchable {
  width: 100%;

  .form-group {
    max-width: 100%;
    --padding-end: 10px;
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px;
    --highlight-height: 0px !important;
    --padding-start: 10px;
    height: 40px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;

    .dropdown {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}


.currency {
  font-family: 'REM', sans-serif !important;
}

a24-select {
  ion-header {
    box-shadow: unset !important;
  }

  .back-button {
    padding: 0px 5px;
  }
}

#paymentDate {
  --width: 255px;

  app-ion-date-time-picker {
    height: 100%;

    .save_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
      ion-button{
        &::part(native){
          height: 100%;
          padding: 10px 20px !important;
          font-size: 13px !important;
          background: transparent;
          color: var(--ion-white-color);
          border-radius: 5px;
          width: auto;
          box-shadow: unset;
          text-transform: capitalize;
        }
      }
    }

    .header_txt {
      height: 100%;
      position: relative;

      .date_picker {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 10px;
        min-width: 190px;
        position: relative;

        input {
          height: 55px;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50% , -50%);
          position:absolute;
          opacity: 0;
        }
      }
    }
  }
}

.hidden_date{

  input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

}

.paid_button {

  .hidden_date {
    position: relative;
  }

}

ion-modal {
  --backdrop-opacity: 0.6 !important;
  --width: 90%;
  --border-radius: 10px;
  --box-shadow: unset;
  --height: auto;

  &#bill-item-modal,
  &#total-modal {
    --width: 75%;
    --height: auto;

    .modal_items {
      padding: 15px 20px;
    }

    ion-item {
      --border-color: unset;
      cursor: pointer;
    }
  }

  &.dropdown-modal {
    --height: 95%;

    ion-content {
      &::part(scroll) {
        padding: 0;
      }

      ion-list {
        margin: 0 !important;
        padding: 0;

        ion-item {
          &.selected {
            background-color: var(--ion-theme-color);
            color: var(--ion-white-color);
          }

          &::part(native) {
            border: unset;
          }
        }
      }
    }
  }

  a24-select {
    background: var(--ion-white-color);

    ion-header {
      ion-toolbar {
        --background: transparent;
        --color: var(--ion-text-color);

        .toolbar-container {
          flex-direction: row-reverse;
        }

        ion-title {
          text-align: end;
        }

        ion-searchbar {
          padding-inline-start: 0 !important;
          padding-inline-end: 0 !important;
          height: 40px;
          --background: transparent !important;
          --border-radius: 0 !important;
          width: 95% !important;
          --box-shadow: unset !important;
          border-radius: 5px;
          border: 1px solid #dedede;
          margin: 0 auto;
        }

        ion-button {
          span {
            color: var(--ion-text-color);
          }
        }
      }

      &::after {
        display: none;
      }
    }

    ion-content {
      &::part(background) {
        background: var(--ion-white-color);
      }
    }

    ion-list {
      padding: 0 !important;

      ion-item,
      ion-radio {
        --padding-start: 10px;
        --inner-padding-end: 10px;
        --inner-border-width: 0;

        //dropdown radio btn colors

        &.\#468BEB {

          //   .alert-radio-label{
          //     color: #468BEB;
          //  }
          &::part(container) {
            border-color: #468BEB !important;
          }

          &::part(mark) {
            background-color: #468BEB !important;

          }
        }

        &.\#D12F2C {

          //   .alert-radio-label{
          //     color: #D12F2C;
          //  }
          &::part(container) {
            border-color: #D12F2C !important;
          }

          &::part(mark) {
            background-color: #D12F2C !important;

          }
        }

        &.\#E3912D {

          //   .alert-radio-label{
          //     color: #E3912D;
          //  }
          &::part(container) {
            border-color: #E3912D !important;
          }

          &::part(mark) {
            background-color: #E3912D !important;

          }
        }

        &.\#D97307 {

          //   .alert-radio-label{
          //     color: #D97307;
          //  }
          &::part(container) {
            border-color: #D97307 !important;
          }

          &::part(mark) {
            background-color: #D97307 !important;

          }
        }

        &.\#2BA62F {

          //   .alert-radio-label{
          //     color: #2BA62F;
          //  }
          &::part(container) {
            border-color: #2BA62F !important;
          }

          &::part(mark) {
            background-color: #2BA62F !important;

          }
        }

        &.\#054C9D {

          //   .alert-radio-label{
          //     color: #054C9D;
          //  }
          &::part(container) {
            border-color: #054C9D !important;
          }

          &::part(mark) {
            background-color: #054C9D !important;

          }
        }

        &.\#1496CE {

          //   .alert-radio-label{
          //     color: #1496CE;
          //  }
          &::part(container) {
            border-color: #1496CE !important;
          }

          &::part(mark) {
            background-color: #1496CE !important;

          }
        }

        &.\#C42F3D {

          //   .alert-radio-label{
          //     color: #C42F3D;
          //  }
          &::part(container) {
            border-color: #C42F3D !important;
          }

          &::part(mark) {
            background-color: #C42F3D !important;

          }
        }

        &.\#D0DD37 {

          //   .alert-radio-label{
          //     color: #D0DD37;
          //  }
          &::part(container) {
            border-color: #D0DD37 !important;
          }

          &::part(mark) {
            background-color: #D0DD37 !important;

          }
        }

        &.\#EA7C03 {

          //   .alert-radio-label{
          //     color: #EA7C03;
          //  }
          &::part(container) {
            border-color: #EA7C03 !important;
          }

          &::part(mark) {
            background-color: #EA7C03 !important;

          }
        }

        &.\#C42F3D {

          //   .alert-radio-label{
          //     color: #C42F3D;
          //  }
          &::part(container) {
            border-color: #C42F3D !important;
          }

          &::part(mark) {
            background-color: #C42F3D !important;

          }
        }

        &.\#972EC4 {

          //   .alert-radio-label{
          //     color: #972EC4;
          //  }
          &::part(container) {
            border-color: #972EC4 !important;
          }

          &::part(mark) {
            background-color: #972EC4 !important;

          }
        }

        &.\#AB4189 {

          //   .alert-radio-label{
          //     color: #AB4189;
          //  }
          &::part(container) {
            border-color: #AB4189 !important;
          }

          &::part(mark) {
            background-color: #AB4189 !important;

          }
        }

        &.\#FEE5AD {

          //   .alert-radio-label{
          //     color: #FEE5AD;
          //  }
          &::part(container) {
            border-color: #FEE5AD !important;
          }

          &::part(mark) {
            background-color: #FEE5AD !important;

          }
        }

        &.\#24466B {

          //   .alert-radio-label{
          //     color: #24466B;
          //  }
          &::part(container) {
            border-color: #24466B !important;
          }

          &::part(mark) {
            background-color: #24466B !important;

          }
        }

        &.\#EDBF9D {

          //   .alert-radio-label{
          //     color: #EDBF9D;
          //  }
          &::part(container) {
            border-color: #EDBF9D !important;
          }

          &::part(mark) {
            background-color: #EDBF9D !important;

          }
        }

        &.\#149D05 {

          //   .alert-radio-label{
          //     color: #149D05;
          //  }
          &::part(container) {
            border-color: #149D05 !important;
          }

          &::part(mark) {
            background-color: #149D05 !important;

          }
        }

        &.\#EA0B03 {

          //   .alert-radio-label{
          //     color: #EA0B03;
          //  }
          &::part(container) {
            border-color: #EA0B03 !important;
          }

          &::part(mark) {
            background-color: #EA0B03 !important;

          }
        }

        &.\#E9C615 {

          //   .alert-radio-label{
          //     color: #E9C615;
          //  }
          &::part(container) {
            border-color: #E9C615 !important;
          }

          &::part(mark) {
            background-color: #E9C615 !important;

          }
        }

        &.\#B9DC82 {

          //   .alert-radio-label{
          //     color: #B9DC82;
          //  }
          &::part(container) {
            border-color: #B9DC82 !important;
          }

          &::part(mark) {
            background-color: #B9DC82 !important;

          }
        }

        &.\#A5548C {

          //   .alert-radio-label{
          //     color: #A5548C;
          //  }
          &::part(container) {
            border-color: #A5548C !important;
          }

          &::part(mark) {
            background-color: #A5548C !important;

          }
        }

        &.\#D773B3 {

          //   .alert-radio-label{
          //     color: #D773B3;
          //  }
          &::part(container) {
            border-color: #D773B3 !important;
          }

          &::part(mark) {
            background-color: #D773B3 !important;

          }
        }

        &.\#3BA6A0 {

          //   .alert-radio-label{
          //     color: #3BA6A0;
          //  }
          &::part(container) {
            border-color: #3BA6A0 !important;
          }

          &::part(mark) {
            background-color: #3BA6A0 !important;

          }
        }

        &.\#A0C663 {

          //   .alert-radio-label{
          //     color: #A0C663;
          //  }
          &::part(container) {
            border-color: #A0C663 !important;
          }

          &::part(mark) {
            background-color: #A0C663 !important;

          }
        }

        &.\#FFC184 {

          //   .alert-radio-label{
          //     color: #FFC184;
          //  }
          &::part(container) {
            border-color: #FFC184 !important;
          }

          &::part(mark) {
            background-color: #FFC184 !important;

          }
        }

        &.\#D16C34 {

          //   .alert-radio-label{
          //     color: #D16C34;
          //  }
          &::part(container) {
            border-color: #D16C34 !important;
          }

          &::part(mark) {
            background-color: #D16C34 !important;

          }
        }

        &.\#FF9A96 {

          //   .alert-radio-label{
          //     color: #FF9A96;
          //  }
          &::part(container) {
            border-color: #FF9A96 !important;
          }

          &::part(mark) {
            background-color: #FF9A96 !important;

          }
        }

        &.\#D9EAFA {

          //   .alert-radio-label{
          //     color: #D9EAFA;
          //  }
          &::part(container) {
            border-color: #D9EAFA !important;
          }

          &::part(mark) {
            background-color: #D9EAFA !important;

          }
        }

        &.\#0AF5F1 {

          //   .alert-radio-label{
          //     color: #0AF5F1;
          //  }
          &::part(container) {
            border-color: #0AF5F1 !important;
          }

          &::part(mark) {
            background-color: #0AF5F1 !important;

          }
        }

        &.\#ff2ba62f {

          //   .alert-radio-label{
          //     color: #ff2ba62f;
          //  }
          &::part(container) {
            border-color: #ff2ba62f !important;
          }

          &::part(mark) {
            background-color: #ff2ba62f !important;

          }
        }

        //dropdown radio btn colors

        &.selected {
          --background: var(--ion-theme-color);

          ion-icon {
            visibility: visible !important;
          }

          p {
            font-weight: 500;
          }
        }

        .new_lists {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .name {
            width: 62%;
          }

          .symbol {
            width: 16%;
            text-align: end;
            font-family: 'REM', sans-serif !important;
          }

          .code {
            width: 20%;
            text-align: end;
          }

          p,
          h1 {
            margin: 5px 0;
            width: 100%;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        ion-icon {
          margin-inline-end: 10px;
          visibility: hidden !important;
          font-size: 24px;
        }
      }
    }
  }

  &.show-modal {
    .drop_down_top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--ion-text-color);
      padding: 0 15px;
      font-size: 20px;

      .cancel_btn {
        display: flex;
        align-items: center;
      }

      p {
        font-weight: 500;
        padding-left: 20px;
      }
    }
  }


  &.add_modal,
  &#jwt-modal {
    --width: 90%;
    --height: auto;
    --border-radius: 10px;
  }


  .model_container {
    background: var(--ion-theme-white-color);

    .model_box {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px;

      .d-flex {
        display: flex;
      }

      .modal_align {
        width: 100%;

        .modal_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          p {
            font-size: 20px;
            font-weight: 900;
            margin: 0;
            width: 90%;
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
          }

          .del_icon {
            background: transparent;
            padding: 10px;
            border-radius: 5px;

            ion-icon {
              font-size: 20px;
              color: var(--ion-unpaid-color);
            }
          }
        }

        ion-card {

          &.ios {
            ion-card-content {
              ion-item {
                .two_side_input {
                  a24-select-component {
                    .select_ion {
                      ion-select {
                        &::part(icon) {
                          top: 0 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ion-card-content {
            ion-item {
              --highlight-height: 0px !important;
              padding: 0;
              margin-bottom: 0;

              .two_side_input {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 10px;

                &.arrow_text {
                  a24-select-component {
                    .select_ion {
                      ion-select {
                        &::part(icon) {
                          top: 0 !important;
                        }
                      }
                    }
                  }
                }

                .left,
                .right {
                  width: 48%;
                }

                a24-select-component {
                  .select_ion {
                    ion-select {
                      width: 100%;
                      max-width: 100%;
                      --padding-end: 10px;
                      border: 1px solid #e5e5e5 !important;
                      border-radius: 5px;
                      height: 40px;
                      --highlight-height: 0px !important;

                      &.md {
                        // &::part(icon) {
                        //   top: 0 !important;
                        //   right: 0;
                        // }
                      }

                      &::part(icon) {
                        top: 8px !important;
                        right: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .modal_body {
        width: 100%;

        ion-card {
          border-radius: unset;

          ion-card-content {
            .check_text {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 10px;
              border-radius: unset;
              color: var(--ion-text-color);

              ion-icon {
                visibility: hidden;
              }

              &.active {
                background: var(--ion-theme-color);

                ion-icon {
                  visibility: visible;
                }
              }
            }

            ion-item {
              padding: 5px 0;
              // &.ion-activated{
              //   --color-
              // }
              --ripple-color: transparent;

            }
          }
        }
      }



      .modal_footer {
        width: 100%;
        margin-top: 10px;

        .btn_box {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .cancel_btn {
            ion-button {
              background-color: transparent;
              margin-right: 10px;
              border: 1px solid var(--ion-theme-color);

              &::part(native) {
                color: var(--ion-text-color) !important;
              }
            }
          }

          ion-button {
            &::part(native) {
              background: transparent;
              color: var(--ion-white-color);
              border-radius: 5px;
              width: auto;
              font-size: 15px;
              box-shadow: unset;
              text-transform: capitalize;
              padding: 20px;
            }
          }

          .save_btn {
            ion-button {
              &::part(native) {
                background: var(--ion-theme-color);
                color: var(--always-white) !important;
              }
            }
          }

          a24-button {
            ion-button {
              &::part(native) {
                background: transparent;
                color: var(--ion-text-color);
                border-radius: 5px;
                width: auto;
                font-size: 15px;
                font-weight: 800;
                box-shadow: unset;
                text-transform: capitalize;
                padding: 20px;
              }
            }

            &.save_btn {
              ion-button {
                &::part(native) {
                  background: var(--ion-theme-color);
                }
              }
            }
          }
        }
      }
    }
  }

  &.selectable-modal {
    --height: 90% !important;

  }
}

button {

  // &.cat-color{
  &.\#468BEB {

    //   .alert-radio-label{
    //     color: #468BEB;
    //  }
    .alert-radio-icon {
      border-color: #468BEB !important;
    }

    .alert-radio-inner {
      background-color: #468BEB !important;

    }
  }

  &.\#D12F2C {

    //   .alert-radio-label{
    //     color: #D12F2C;
    //  }
    .alert-radio-icon {
      border-color: #D12F2C !important;
    }

    .alert-radio-inner {
      background-color: #D12F2C !important;

    }
  }

  &.\#E3912D {

    //   .alert-radio-label{
    //     color: #E3912D;
    //  }
    .alert-radio-icon {
      border-color: #E3912D !important;
    }

    .alert-radio-inner {
      background-color: #E3912D !important;

    }
  }

  &.\#D97307 {

    //   .alert-radio-label{
    //     color: #D97307;
    //  }
    .alert-radio-icon {
      border-color: #D97307 !important;
    }

    .alert-radio-inner {
      background-color: #D97307 !important;

    }
  }

  &.\#2BA62F {

    //   .alert-radio-label{
    //     color: #2BA62F;
    //  }
    .alert-radio-icon {
      border-color: #2BA62F !important;
    }

    .alert-radio-inner {
      background-color: #2BA62F !important;

    }
  }

  &.\#054C9D {

    //   .alert-radio-label{
    //     color: #054C9D;
    //  }
    .alert-radio-icon {
      border-color: #054C9D !important;
    }

    .alert-radio-inner {
      background-color: #054C9D !important;

    }
  }

  &.\#1496CE {

    //   .alert-radio-label{
    //     color: #1496CE;
    //  }
    .alert-radio-icon {
      border-color: #1496CE !important;
    }

    .alert-radio-inner {
      background-color: #1496CE !important;

    }
  }

  &.\#C42F3D {

    //   .alert-radio-label{
    //     color: #C42F3D;
    //  }
    .alert-radio-icon {
      border-color: #C42F3D !important;
    }

    .alert-radio-inner {
      background-color: #C42F3D !important;

    }
  }

  &.\#D0DD37 {

    //   .alert-radio-label{
    //     color: #D0DD37;
    //  }
    .alert-radio-icon {
      border-color: #D0DD37 !important;
    }

    .alert-radio-inner {
      background-color: #D0DD37 !important;

    }
  }

  &.\#EA7C03 {

    //   .alert-radio-label{
    //     color: #EA7C03;
    //  }
    .alert-radio-icon {
      border-color: #EA7C03 !important;
    }

    .alert-radio-inner {
      background-color: #EA7C03 !important;

    }
  }

  &.\#C42F3D {

    //   .alert-radio-label{
    //     color: #C42F3D;
    //  }
    .alert-radio-icon {
      border-color: #C42F3D !important;
    }

    .alert-radio-inner {
      background-color: #C42F3D !important;

    }
  }

  &.\#972EC4 {

    //   .alert-radio-label{
    //     color: #972EC4;
    //  }
    .alert-radio-icon {
      border-color: #972EC4 !important;
    }

    .alert-radio-inner {
      background-color: #972EC4 !important;

    }
  }

  &.\#AB4189 {

    //   .alert-radio-label{
    //     color: #AB4189;
    //  }
    .alert-radio-icon {
      border-color: #AB4189 !important;
    }

    .alert-radio-inner {
      background-color: #AB4189 !important;

    }
  }

  &.\#FEE5AD {

    //   .alert-radio-label{
    //     color: #FEE5AD;
    //  }
    .alert-radio-icon {
      border-color: #FEE5AD !important;
    }

    .alert-radio-inner {
      background-color: #FEE5AD !important;

    }
  }

  &.\#24466B {

    //   .alert-radio-label{
    //     color: #24466B;
    //  }
    .alert-radio-icon {
      border-color: #24466B !important;
    }

    .alert-radio-inner {
      background-color: #24466B !important;

    }
  }

  &.\#EDBF9D {

    //   .alert-radio-label{
    //     color: #EDBF9D;
    //  }
    .alert-radio-icon {
      border-color: #EDBF9D !important;
    }

    .alert-radio-inner {
      background-color: #EDBF9D !important;

    }
  }

  &.\#149D05 {

    //   .alert-radio-label{
    //     color: #149D05;
    //  }
    .alert-radio-icon {
      border-color: #149D05 !important;
    }

    .alert-radio-inner {
      background-color: #149D05 !important;

    }
  }

  &.\#EA0B03 {

    //   .alert-radio-label{
    //     color: #EA0B03;
    //  }
    .alert-radio-icon {
      border-color: #EA0B03 !important;
    }

    .alert-radio-inner {
      background-color: #EA0B03 !important;

    }
  }

  &.\#E9C615 {

    //   .alert-radio-label{
    //     color: #E9C615;
    //  }
    .alert-radio-icon {
      border-color: #E9C615 !important;
    }

    .alert-radio-inner {
      background-color: #E9C615 !important;

    }
  }

  &.\#B9DC82 {

    //   .alert-radio-label{
    //     color: #B9DC82;
    //  }
    .alert-radio-icon {
      border-color: #B9DC82 !important;
    }

    .alert-radio-inner {
      background-color: #B9DC82 !important;

    }
  }

  &.\#A5548C {

    //   .alert-radio-label{
    //     color: #A5548C;
    //  }
    .alert-radio-icon {
      border-color: #A5548C !important;
    }

    .alert-radio-inner {
      background-color: #A5548C !important;

    }
  }

  &.\#D773B3 {

    //   .alert-radio-label{
    //     color: #D773B3;
    //  }
    .alert-radio-icon {
      border-color: #D773B3 !important;
    }

    .alert-radio-inner {
      background-color: #D773B3 !important;

    }
  }

  &.\#3BA6A0 {

    //   .alert-radio-label{
    //     color: #3BA6A0;
    //  }
    .alert-radio-icon {
      border-color: #3BA6A0 !important;
    }

    .alert-radio-inner {
      background-color: #3BA6A0 !important;

    }
  }

  &.\#A0C663 {

    //   .alert-radio-label{
    //     color: #A0C663;
    //  }
    .alert-radio-icon {
      border-color: #A0C663 !important;
    }

    .alert-radio-inner {
      background-color: #A0C663 !important;

    }
  }

  &.\#FFC184 {

    //   .alert-radio-label{
    //     color: #FFC184;
    //  }
    .alert-radio-icon {
      border-color: #FFC184 !important;
    }

    .alert-radio-inner {
      background-color: #FFC184 !important;

    }
  }

  &.\#D16C34 {

    //   .alert-radio-label{
    //     color: #D16C34;
    //  }
    .alert-radio-icon {
      border-color: #D16C34 !important;
    }

    .alert-radio-inner {
      background-color: #D16C34 !important;

    }
  }

  &.\#FF9A96 {

    //   .alert-radio-label{
    //     color: #FF9A96;
    //  }
    .alert-radio-icon {
      border-color: #FF9A96 !important;
    }

    .alert-radio-inner {
      background-color: #FF9A96 !important;

    }
  }

  &.\#D9EAFA {

    //   .alert-radio-label{
    //     color: #D9EAFA;
    //  }
    .alert-radio-icon {
      border-color: #D9EAFA !important;
    }

    .alert-radio-inner {
      background-color: #D9EAFA !important;

    }
  }

  &.\#0AF5F1 {

    //   .alert-radio-label{
    //     color: #0AF5F1;
    //  }
    .alert-radio-icon {
      border-color: #0AF5F1 !important;
    }

    .alert-radio-inner {
      background-color: #0AF5F1 !important;

    }
  }

  &.\#ff2ba62f {

    //   .alert-radio-label{
    //     color: #ff2ba62f;
    //  }
    .alert-radio-icon {
      border-color: #ff2ba62f !important;
    }

    .alert-radio-inner {
      background-color: #ff2ba62f !important;

    }
  }

  // }
}

/* custom-modal */

app-custom-modal-component {
  .custom_modal {
    .header {
      .icon_sec {
        padding-right: 10px;

        &.conform-img {
          color: #ffb30e !important;
        }

        &.success-img {
          color: #4bb543 !important;
        }

        &.failure-img {
          color: #d74637 !important;
        }

        ion-icon {
          font-size: 34px;
        }
      }

      .header_text {
        h1 {
          font-size: 20px;
          font-weight: 900;
          margin: 0;
          width: 90%;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          color: var(--ion-text-color);
        }

        p {
          color: var(--ion-text-color);
          font-size: 14px;
          margin: 10px 0;
        }
      }
    }
  }
}

/* custom-modal */

app-bills-list {
  ion-card {
    ion-card-content {
      ion-item {
        padding: 0;
        border: 1px solid #e5e5e5 !important;
        margin-bottom: 15px;
        --min-height: 40px;

        a24-range-date-picker {
          .datepicker {
            border: unset !important;
            border-radius: unset !important;
            height: 100%;
          }
        }
      }
    }
  }
}

/* Gallery */

/* intro slider */

a24-intro-slides {
  ion-content {
    &.slider_section {
      .intro_view {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        z-index: 1;
        padding: 5px 30px 30px;

        swiper-container {
          swiper-slide {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
            height: 95%;
          }
        }

        .intro_head {
          width: 100%;
          height: 80px;
          display: flex;

          .logo {
            width: 60%;

            p {
              text-align: start;
              font-size: 16px;
              font-weight: 500;
              margin: 0;
              line-height: 30px;
            }

            ion-icon {
              width: 80px;
              height: 80px;
            }
          }

          ion-button {
            --background-activated: transparent;
            --background-focused: transparent;
            --background-hover: transparent;
            --background-activated-opacity: 0;
            --background-focused-opacity: 0;
            --background-hover-opacity: 0;

            &::part(native) {
              --padding-start: 0;
              --padding-end: 0;
              line-height: 30px;
            }
          }
        }
      }

      swiper-container {
        swiper-slide {
          .middle_section {
            height: 80%;
          }

          .slide_img {
            width: 100%;

            ion-icon {
              width: 100%;
              height: 270px;
            }
          }

          .bottom_content {
            width: 100%;

            .slide_content {
              text-align: center;

              h2 {
                margin: 15px 0px 15px;
              }

              p {
                font-size: 14px;
                line-height: 26px;
              }
            }
          }

          .slide_button {
            display: flex;
            align-items: center;
            justify-content: center;

            ion-button {
              background: var(--ion-theme-color);

              &::part(native) {
                color: var(--ion-white-color);
              }
            }
          }
        }
      }
    }
  }
}

.save_btn {
  ion-button {

    background-color: var(--ion-theme-color);
    color: var(--always-white);
  }
}

/* intro slider */

/* intro page */
a24-intro-slides {
  ion-content.slider_section {
    .intro_view {
      padding: 25px 20px;
      background-color: var(--ion-white-color);

      .intro_head {
        .logo {
          ion-icon {
            width: 100px;
            height: 100px;
          }
        }

        .skip {
          // font-weight: 700;
          font-size: 16px;
          text-transform: capitalize;
          color: var(--ion-theme-color);
        }
      }

      swiper-container {
        .slide_button {
          ion-button {
            width: 200px;
            border-radius: 5px;
            text-transform: capitalize;

            &::part(native) {
              // font-weight: 600;
            }
          }
        }

        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 25px !important;
            height: 4px !important;
            background: #e4e4e4 !important;
            opacity: 3;
            border-radius: unset;
          }

          .swiper-pagination-bullet-active {
            background: var(--ion-text-color) !important;
          }
        }
      }
    }
  }
}

app-add-bill {
  .ngx-header {
    ion-toolbar {
      .main_header_sec {
        .left_sec {
          width: 100%;
        }
      }
    }
  }

  .data_list {
    height: 100%;
    margin: 0 auto 0 !important;
    padding: 0 15px 90px !important;
  }
}

app-clean-calc {
  .data_list {
    &.calc_list {
      background: var(--ion-bg-theme);
      padding: 30px 35px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .calc_container {
        position: relative;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 6px;
        overflow: hidden;
        z-index: 10;
        backdrop-filter: blur(15px);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
        width: 100%;

        .row {
          position: relative;
          display: flex;

          &:last-child {
            .col {
              &:first-child {
                width: 30%;
              }

              &:nth-child(2) {
                width: 30%;
              }

              &:last-child {
                width: 60%;
                background-color: var(--ion-theme-color);
              }
            }

          }

          .col {
            display: grid;
            height: 75px;
            place-items: center;
            width: 100%;
            color: var(--ion-white-color);
            font-weight: 400;
            cursor: pointer;
            font-size: 20px;
            user-select: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            border-right: 1px solid rgba(255, 255, 255, 0.05);
            transition: 0.5s;
            position: relative;

            .inner_value {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover {
                transition: 0s;
                background: rgba(255, 255, 255, 0.05);
              }

              &:active {
                background-color: var(--ion-theme-color);
              }
            }
          }
        }
      }

      .value {
        grid-column: span 4;
        height: 140px;
        width: 100%;
        text-align: right;
        border: none;
        outline: none;
        padding: 10px;
        font-size: 30px;
        background: transparent;
        color: var(--ion-white-color);
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        border-right: 1px solid rgba(255, 255, 255, 0.05);
      }
    }
  }
}


.no_network {
  height: 100%;
  position: relative;
}

.network_error {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  ion-icon {
    width: 100%;
    height: 200px;
  }

  h2 {
    color: #000;
    text-align: center;
    line-height: 27px;
    font-size: 16px;
    width: 80%;
  }

  ion-button {
    --background: var(--ion-theme-color);
    --box-shadow: unset;
    text-transform: capitalize;
  }
}

/* intro page */

/* Guide Page */
app-guide {
  background: #fff;
  overflow-y: auto !important;

  .help_header {
    background: #875aff;
    padding-top: 40px;

    .help-bg {
      width: 100%;
      max-width: 1170px;
      margin: 0px auto;

      .logo {
        width: auto;
        height: 60px;
        margin: 0 auto;
        display: block;
        position: relative;

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0;
          right: 0;
          width: auto;
          height: 60px;
        }
      }

      h4 {
        color: #F8C644;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin: 30px 0px;
      }

      .guide_img {
        margin: 40px auto 0;
        display: block;

        img {
          width: 300px;
          height: auto;
        }
      }

      ion-input {
        background: #fff;
        color: #000;
        box-shadow: 1px 1px 10px #4a0093;
        width: 50%;
        height: 45px;
        border-radius: 15px;
        margin: 0 auto;
        position: relative;
        --padding-start: 15px;
        --highlight-color-focused:unset;
        --highlight-color-valid: unset;
        --highlight-color-invalid:unset;
        --highlight-color: unset;

        ion-icon {
          position: absolute;
          top: 14px;
          right: 15px;
          font-size: 18px;
        }
      }
    }
  }

  .help-wrap {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    .faq {
      width: 60%;
      margin: 50px auto;

      .faq_section {
        .faq_tabs {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          .group_title {
            color: #a1a1a1;
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            margin: 30px 15px 15px 0px;
            cursor: pointer;

            &:hover {
              color: #875aff;
            }

            &.active {
              color: #875aff;
              font-size: 15px;
            }
          }
        }

        .faq_info {
          border: 1px solid #d6d6d6;
          border-bottom: 0;

          &:last-child {
            border-bottom: 1px solid #d6d6d6 !important;
          }

          .faq_info_action {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            ion-icon {
              width: 20px;
              height: 20px;
              margin: 0 15px;
              color: #000;
            }

            &.active {
              background: #875aff !important;
              color: #fff;

              ion-icon {
                color: #fff;
              }

              button {
                color: #fff;
              }

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #875aff;
            }
          }

          button {
            background: transparent;
            width: 100%;
            height: 59px;
            text-align: left;
            padding: 0px 20px;
            text-transform: capitalize;
            cursor: pointer;
            border: 0;
            color: #545454;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            &.active {
              background: #875aff !important;
              color: #fff;

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #875aff;
            }
          }

          ion-item {
            --border-style: 0;
            padding: 15px 0px;

            div {
              margin: 0 auto;
            }
            // youtube-player{
            //   iframe{
            //     width: 100%;
            //     height: 100%;
            //   }
            // }
            ion-item {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  .back_btn {
    position: absolute;
    top: 30px;
    left: 30px;
    cursor: pointer;

    a {
      background: none;
      border: 1px solid #fff;
      color: #fff;
      font: inherit;
      letter-spacing: inherit;
      $color: #fff;
      width: 120px;
      height: 36px;
      border-radius: 30px;
      text-transform: inherit;
      transition: color 0.5s;
      display: block;
      text-align: center;
      line-height: 36px;
      text-decoration: none;

      &:hover {
        animation: halftone 1s forwards;
        background: radial-gradient(circle, $color 0.2em, transparent 0.25em) 0 0 / 1.25em 1.25em, radial-gradient(circle, $color 0.2em, transparent 0.25em) 6.25em 6.25em / 1.25em 1.25em;
        color: #875aff;
      }
    }

    @keyframes halftone {
      100% {
        background-size: 2.375em 2.375em, 0.1em 0.1em;
      }
    }
  }
}


.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 15px;
  height: 15px;
  background: rgba(255, 255, 255, 0.4);
  animation: animate 25s linear infinite;
  bottom: -150px;

}

.circles li:nth-child(1) {
  left: 25%;
  width: 50px;
  height: 50px;
  animation-delay: 2s;
}


.circles li:nth-child(2) {
  left: 10%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 3s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 15px;
  height: 15px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 5s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 15px;
  height: 15px;
  animation-delay: 1s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 70px;
  height: 70px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 90px;
  height: 90px;
  animation-delay: 5s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 20px;
  height: 20px;
  animation-delay: 3s;
  animation-duration: 6s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 5s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 90px;
  height: 90px;
  animation-delay: 0s;
  animation-duration: 6s;
}



@keyframes animate {

  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}


@media only screen and (max-width: 820px) {
  app-guide {
    .help-wrap {
      .faq {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  .pro_col {
    padding-right: 45px;
  }

  .web_page.web_chartPage {
    ion-toolbar {
      .pro_Section {
        width: 95%;
        margin: 0;

        ion-title.title-default {
          padding-inline: 10px;
          font-size: 16px !important;
        }

        .pro_btns {
          padding: 6px 20px;
          font-size: 12px;
        }
      }
    }
  }

  .modal_pro_sec {
    padding: 5px 20px;

    .pro_btns {
      padding: 5px 20px;
    }
  }

  app-guide {
    .help_header {
      .help-bg {
        .logo {
          height: 46px;

          img {
            height: 46px;
          }
        }

        ion-input {
          width: 86%;
        }
      }
    }

    .help-wrap {
      .faq {
        width: 84%;
      }
    }

    .back_btn {
      top: 15px;
      left: 15px;

      a {
        width: 70px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
}

/* Guide Page */

.input_sec {
  .show-ranges {
    // top: auto !important;
    // left: auto !important;
    // right: 0 !important;
    z-index: 99999 !important;
    contain: content;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: max-content !important;
    padding: 15px 15px 0;

    ul {
      li {
        button {
          color: var(--ion-text-color);

          &.active {
            background-color: var(--ion-theme-color) !important;

          }
        }
      }

    }

    .calendar {
      max-width: 100%;
      margin: 4px;

      .calendar-table {
        thead {
          tr:first-child {
            th {
              font-size: 16px;
              padding: 0;
              line-height: 24px;
              color: #404040;
              height: 15px;
            }

            th:nth-child(2) {
              .dropdowns {
                width: 100px;
              }
            }
          }
        }
      }

      tr {
        td.off, td.off.in-range, td.off.start-date,td.off.end-date{
          color: #999 !important;
        }
        td {
          padding: 4px !important;
          white-space: nowrap;
          text-align: center;
          min-width: 30px;
          border-radius: 0;
          font-weight: 400 !important;
          color: #000;

          &.active {
            background-color: var(--ion-theme-color);

            &:hover {
              background-color: var(--ion-theme-color);
            }

            &.available {
              color: var(--ion-white-color);

              &.off {

                &.start-date,
                &.end-date {
                  color: var(--ion-text-color) !important;
                  background-color: transparent;

                  &:hover {
                    color: var(--ion-text-color) !important;
                  }
                }
              }

            }
          }

          &.available {
            &.off {

              &.start-date,
              &.end-date {
                color: var(--ion-text-color) !important;
              }
            }
          }
        }
      }

      .week-days {
        th {
          text-transform: uppercase;
          color: #404040;
          font-weight: 700;
          padding: 4px 8px !important;
          font-size: 12px;
        }
      }
    }
  }
}


.date_picker {
  .select_datePic {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #e5e5e5 !important;
    border-radius: 5px;
    margin: 0 !important;
    height: 40px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      border: unset;
      font-size: 14px;
      padding-left: 15px;
      outline: unset;
      background: transparent;
      color: var(--ion-text-color);
    }

    .calendar_icon {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #b1b1b1;
    }
  }
}


.drag_slider {
  margin-top: 20px;
}

.show_datepicker {
  ion-tab-bar {
    display: none
  }
}

.show-ranges {

  &::after,
  &::before {
    position: relative !important;
  }
}

@import "/src/theme/responsive.scss";

/* if you want device default dark mode color you can use this media query to change the dark mode color  */

/*
@media (prefers-color-scheme: dark) {
  :root {
    --ion-theme-color: #875AFF;
    --ion-theme-light-bg: #080A0B;
    --ion-white-color: #000000;
    --ion-text-color: #ffffff;
    --ion-card-color: #080A0B;
    --ion-theme-white-color: #080A0B;
    --ion-paid-color: #2FAA58;
    --ion-unpaid-color: #FF6B00;
    --ion-upcoming-color: #407BFF;
    --ion-overdue-color: #E5001C;
    --ion-color-base: #171717 !important;
    --ion-bg-theme: #080A0B;
    --ion-color-danger: #E5001C;
    --dark_btn: #875AFF;
    --white-bg: #080A0B;
    --always-white:#ffffff;
  }

  ion-tabs {
    ion-tab-bar {
      ion-tab-button {
        --background: var(--white-bg);
        box-shadow: 0px -8px 6px rgb(143 143 143 / 10%);
      }

      svg {
        filter: brightness(0) saturate(100%) invert(4%) sepia(10%) saturate(696%) hue-rotate(155deg) brightness(96%) contrast(100%);
      }
    }
  }

  ngx-header {
    ion-toolbar {
      .main_header_sec {
        .left_sec {
          .page_title {
            ion-buttons {
              ion-button {
                filter: brightness(0) saturate(100%) invert(99%) sepia(4%) saturate(185%) hue-rotate(180deg) brightness(118%) contrast(100%);
              }
            }
          }
        }
      }
    }
  }

  ion-card{
    ion-card-content{
      ion-item{
        margin-bottom: 10px;
      }
    } 
  } 

}
*/

/* delete account design you can use all project */

.delete_modal_page {

  background: #EDF2F6;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 65px;
  flex-direction: column;
  text-align: center;


  h3{
    color: #000;
    text-align: center;

  }

  .product_name {
    margin-top: 60px;
    width: 100%;
    height: 60px;
  }


  .delete_account {
    max-width: 435px;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    padding: 50px 20px;
    border-radius: 20px;

    header {
      ion-icon {
        width: 130px;
        height: 140px;
      }
    }

    .modal_body_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;

      .btn_sec {
        ion-button {
          width: 100%;
          height: 50px;
          margin-top: 20px;
          --background: #ef4b4b;
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 700;
          color: #fff;
          --box-shadow: unset;
        }
      }

      .hide-err {
        margin: 10px 0 0;
        font-size: 14px !important;
        color: #eb445a;
      }

      .from_sec {
        width: 100%;
      }

      .des {
        font-size: 24px;
        font-weight: 800;
        margin: 20px 0 8px;
        color: #000;
      }

      h5 {
        width: 90%;
        font-size: 15px;
        line-height: 23px;
        color: #8b9198;
        font-weight: 500;
        margin: 5px 0 0;
      }

      input {
        text-align: left;
        border: 1px solid #8b91a1;
        border-radius: 60px;
        height: 50px;
        padding: 0 25px;
        margin: 15px 0 0;
        font-size: 16px;
        font-weight: 500;
        color: #141414;
      }

      ion-input {
        --highlight-color-focused: unset;
        --highlight-color-valid: unset;
        --highlight-color-invalid: unset;
        --highlight-color: unset;
      }
    }
  }

}

/* delete account design */

/* calendar-overview */

app-calendar-overview {
  .filter_container {
    .date_picker {
      min-width: 100%;
      width: 100%;

      .shown {
        width: 100%;
        border-radius: 0;
        // border-bottom: 1px solid #d4d4d4;
        box-shadow: unset;

        .calendar-table {
          padding: 0;
        }

        .calendar {
          width: 100%;
          max-width: 100%;
          margin: 0;

          th,
          td {
            min-width: 100%;
            border: 1px solid #f1f1f1;
          }

          th {
            height: 60px !important;
            color: var(--ion-theme-color);
            border-top: 1px solid #f1f1f1;
            border-bottom: 1px solid #f1f1f1;
            border-left: unset;
            border-right: unset;

            &:first-child {
              border-left: 1px solid #f1f1f1 !important;
            }

            &:last-child {
              border-right: 1px solid #f1f1f1 !important;
            }

            &.next,
            &.prev {
              opacity: 1;
              background-size: 10px;
              border-radius: 0;
              display: revert;

            }
          }

          td {
            opacity: 1;
            height: 60px;
            width: 6vw;

            &.active {
              background-color: transparent;

              span {
                background-color: var(--ion-theme-color);
                // padding: 9px 15px;
                border-radius: 85px;
                width: 40px;
                height: 40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
              }

              &.available {

                &:hover {
                  span {
                    background-color: var(--ion-theme-color) !important;
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  }

                }

              }


            }

            &.available {
              &:hover {
                background-color: transparent !important;

                span {
                  background-color: #eee;
                  // padding: 9px 15px;
                  border-radius: 85px;
                  width: 40px;
                  height: 40px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .empty_boxs{
  
    .list_card{
   
      position: relative;
   
      .app-empty{
   
       transform: translate(-50%, 10%);
   
      }
   
    }

  }

}

/* calendar-overview */

/* paid date picker */
.date-modal{

  #bill-item-modal{
  
      display: none;
  
  }
  
}
/* paid date picker */

/* ios */

.ios{
  *, body{
    -webkit-font-smoothing: initial !important;
  }




  ion-action-sheet{

    .action-sheet-wrapper{
      .action-sheet-group{
        .action-sheet-selected{
          &::after{
            content: unset !important;
          }
        }
      } 
    } 

    .action-sheet-container{
      .action-sheet-group {
        max-height: 600px;
        overflow-y: scroll;
      }
      
    }
    

  } 

  .save_btn{
    ion-button{
      color: var(--always-white) !important;
    }
  } 

  ion-modal{
    &.dropdown-modal{
      a24-select{

        ion-header{
          ion-toolbar{
            padding-top: 13px !important;
          }
          ion-searchbar{
            padding-bottom: 0px;
            margin-bottom: 10px;
            input{
              padding-inline-start: 45px !important;
            }
            .searchbar-search-icon{
              inset-inline-start: 15px;
            }
          }
        } 

        ion-list{
          ion-item,
          ion-radio {
            --padding-start: 10px;
            --inner-padding-end: 10px;
            --inner-border-width: 0;

            &::part(mark){
              border: unset !important;
            }

            &.radio-checked{

            &::part(mark){
              transform: scale3d(1, 1, 1);
            }
            }

            &::part(container) {

              width: 20px;
              height: 20px;
              border-radius: 50%;
              border-width: 2px;
              border-style: solid;
            }

            &::part(mark){
              border-radius: 50%;
              width: 10px;
              height: 10px;
              transform: scale3d(0, 0, 0);
              transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1) 0s, -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
            }
    
            //dropdown radio btn colors
    
            &.\#468BEB {
    
              //   .alert-radio-label{
              //     color: #468BEB;
              //  }
              &::part(container) {
                border-color: #468BEB !important;
              }
    
              &::part(mark) {
                background-color: #468BEB !important;
    
              }
            }
    
            &.\#D12F2C {
    
              //   .alert-radio-label{
              //     color: #D12F2C;
              //  }
              &::part(container) {
                border-color: #D12F2C !important;
              }
    
              &::part(mark) {
                background-color: #D12F2C !important;
    
              }
            }
    
            &.\#E3912D {
    
              //   .alert-radio-label{
              //     color: #E3912D;
              //  }
              &::part(container) {
                border-color: #E3912D !important;
              }
    
              &::part(mark) {
                background-color: #E3912D !important;
    
              }
            }
    
            &.\#D97307 {
    
              //   .alert-radio-label{
              //     color: #D97307;
              //  }
              &::part(container) {
                border-color: #D97307 !important;
              }
    
              &::part(mark) {
                background-color: #D97307 !important;
    
              }
            }
    
            &.\#2BA62F {
    
              //   .alert-radio-label{
              //     color: #2BA62F;
              //  }
              &::part(container) {
                border-color: #2BA62F !important;
              }
    
              &::part(mark) {
                background-color: #2BA62F !important;
    
              }
            }
    
            &.\#054C9D {
    
              //   .alert-radio-label{
              //     color: #054C9D;
              //  }
              &::part(container) {
                border-color: #054C9D !important;
              }
    
              &::part(mark) {
                background-color: #054C9D !important;
    
              }
            }
    
            &.\#1496CE {
    
              //   .alert-radio-label{
              //     color: #1496CE;
              //  }
              &::part(container) {
                border-color: #1496CE !important;
              }
    
              &::part(mark) {
                background-color: #1496CE !important;
    
              }
            }
    
            &.\#C42F3D {
    
              //   .alert-radio-label{
              //     color: #C42F3D;
              //  }
              &::part(container) {
                border-color: #C42F3D !important;
              }
    
              &::part(mark) {
                background-color: #C42F3D !important;
    
              }
            }
    
            &.\#D0DD37 {
    
              //   .alert-radio-label{
              //     color: #D0DD37;
              //  }
              &::part(container) {
                border-color: #D0DD37 !important;
              }
    
              &::part(mark) {
                background-color: #D0DD37 !important;
    
              }
            }
    
            &.\#EA7C03 {
    
              //   .alert-radio-label{
              //     color: #EA7C03;
              //  }
              &::part(container) {
                border-color: #EA7C03 !important;
              }
    
              &::part(mark) {
                background-color: #EA7C03 !important;
    
              }
            }
    
            &.\#C42F3D {
    
              //   .alert-radio-label{
              //     color: #C42F3D;
              //  }
              &::part(container) {
                border-color: #C42F3D !important;
              }
    
              &::part(mark) {
                background-color: #C42F3D !important;
    
              }
            }
    
            &.\#972EC4 {
    
              //   .alert-radio-label{
              //     color: #972EC4;
              //  }
              &::part(container) {
                border-color: #972EC4 !important;
              }
    
              &::part(mark) {
                background-color: #972EC4 !important;
    
              }
            }
    
            &.\#AB4189 {
    
              //   .alert-radio-label{
              //     color: #AB4189;
              //  }
              &::part(container) {
                border-color: #AB4189 !important;
              }
    
              &::part(mark) {
                background-color: #AB4189 !important;
    
              }
            }
    
            &.\#FEE5AD {
    
              //   .alert-radio-label{
              //     color: #FEE5AD;
              //  }
              &::part(container) {
                border-color: #FEE5AD !important;
              }
    
              &::part(mark) {
                background-color: #FEE5AD !important;
    
              }
            }
    
            &.\#24466B {
    
              //   .alert-radio-label{
              //     color: #24466B;
              //  }
              &::part(container) {
                border-color: #24466B !important;
              }
    
              &::part(mark) {
                background-color: #24466B !important;
    
              }
            }
    
            &.\#EDBF9D {
    
              //   .alert-radio-label{
              //     color: #EDBF9D;
              //  }
              &::part(container) {
                border-color: #EDBF9D !important;
              }
    
              &::part(mark) {
                background-color: #EDBF9D !important;
    
              }
            }
    
            &.\#149D05 {
    
              //   .alert-radio-label{
              //     color: #149D05;
              //  }
              &::part(container) {
                border-color: #149D05 !important;
              }
    
              &::part(mark) {
                background-color: #149D05 !important;
    
              }
            }
    
            &.\#EA0B03 {
    
              //   .alert-radio-label{
              //     color: #EA0B03;
              //  }
              &::part(container) {
                border-color: #EA0B03 !important;
              }
    
              &::part(mark) {
                background-color: #EA0B03 !important;
    
              }
            }
    
            &.\#E9C615 {
    
              //   .alert-radio-label{
              //     color: #E9C615;
              //  }
              &::part(container) {
                border-color: #E9C615 !important;
              }
    
              &::part(mark) {
                background-color: #E9C615 !important;
    
              }
            }
    
            &.\#B9DC82 {
    
              //   .alert-radio-label{
              //     color: #B9DC82;
              //  }
              &::part(container) {
                border-color: #B9DC82 !important;
              }
    
              &::part(mark) {
                background-color: #B9DC82 !important;
    
              }
            }
    
            &.\#A5548C {
    
              //   .alert-radio-label{
              //     color: #A5548C;
              //  }
              &::part(container) {
                border-color: #A5548C !important;
              }
    
              &::part(mark) {
                background-color: #A5548C !important;
    
              }
            }
    
            &.\#D773B3 {
    
              //   .alert-radio-label{
              //     color: #D773B3;
              //  }
              &::part(container) {
                border-color: #D773B3 !important;
              }
    
              &::part(mark) {
                background-color: #D773B3 !important;
    
              }
            }
    
            &.\#3BA6A0 {
    
              //   .alert-radio-label{
              //     color: #3BA6A0;
              //  }
              &::part(container) {
                border-color: #3BA6A0 !important;
              }
    
              &::part(mark) {
                background-color: #3BA6A0 !important;
    
              }
            }
    
            &.\#A0C663 {
    
              //   .alert-radio-label{
              //     color: #A0C663;
              //  }
              &::part(container) {
                border-color: #A0C663 !important;
              }
    
              &::part(mark) {
                background-color: #A0C663 !important;
    
              }
            }
    
            &.\#FFC184 {
    
              //   .alert-radio-label{
              //     color: #FFC184;
              //  }
              &::part(container) {
                border-color: #FFC184 !important;
              }
    
              &::part(mark) {
                background-color: #FFC184 !important;
    
              }
            }
    
            &.\#D16C34 {
    
              //   .alert-radio-label{
              //     color: #D16C34;
              //  }
              &::part(container) {
                border-color: #D16C34 !important;
              }
    
              &::part(mark) {
                background-color: #D16C34 !important;
    
              }
            }
    
            &.\#FF9A96 {
    
              //   .alert-radio-label{
              //     color: #FF9A96;
              //  }
              &::part(container) {
                border-color: #FF9A96 !important;
              }
    
              &::part(mark) {
                background-color: #FF9A96 !important;
    
              }
            }
    
            &.\#D9EAFA {
    
              //   .alert-radio-label{
              //     color: #D9EAFA;
              //  }
              &::part(container) {
                border-color: #D9EAFA !important;
              }
    
              &::part(mark) {
                background-color: #D9EAFA !important;
    
              }
            }
    
            &.\#0AF5F1 {
    
              //   .alert-radio-label{
              //     color: #0AF5F1;
              //  }
              &::part(container) {
                border-color: #0AF5F1 !important;
              }
    
              &::part(mark) {
                background-color: #0AF5F1 !important;
    
              }
            }
    
            &.\#ff2ba62f {
    
              //   .alert-radio-label{
              //     color: #ff2ba62f;
              //  }
              &::part(container) {
                border-color: #ff2ba62f !important;
              }
    
              &::part(mark) {
                background-color: #ff2ba62f !important;
    
              }
            }
    
            //dropdown radio btn colors
    
            &.selected {
              --background: var(--ion-theme-color);
    
              ion-icon {
                visibility: visible !important;
              }
    
              p {
                font-weight: 500;
              }
            }
    
            .new_lists {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
    
              .name {
                width: 62%;
              }
    
              .symbol {
                width: 16%;
                text-align: end;
                font-family: 'REM', sans-serif !important;
              }
    
              .code {
                width: 20%;
                text-align: end;
              }
    
              p,
              h1 {
                margin: 5px 0;
                width: 100%;
                white-space: nowrap;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
    
            ion-icon {
              margin-inline-end: 10px;
              visibility: hidden !important;
              font-size: 24px;
            }
          }
        } 
      } 
    }
  } 
}


/* ios */